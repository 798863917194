import axios from "axios";
import { createContext, useState, useContext } from "react";
import toast from "../utils/toast";
import vibrator from "../utils/vibrator";
import logger from "../utils/logger";
import { notifications } from "./NotificationProvider";

const orders = createContext();

const { Provider } = orders;

const OrderProvider = ({ children }) => {
  const [orders, setStateOrders] = useState(
    JSON.parse(sessionStorage.getItem("orders")) || null
  );

  const [unsuccessfulKOTs, setStateUnsuccessfulKOTs] = useState(
    JSON.parse(sessionStorage.getItem("unsuccessfulKOTs")) || []
  );

  const notificationsContext = useContext(notifications);

  const setOrders = (orders) => {
    sessionStorage.setItem("orders", JSON.stringify(orders));
    setStateOrders(orders);
  };

  const addOrder = (order) => {
    setStateOrders((prevOrders) => {
      const newOrders = { ...prevOrders, [order.table._id]: order };
      sessionStorage.setItem("orders", JSON.stringify(newOrders));
      return newOrders;
    });
  };

  const addUnsuccessfulKOT = (kot) => {
    setStateUnsuccessfulKOTs((prev) => {
      const newKOTs = [...prev, kot];
      sessionStorage.setItem("unsuccessfulKOTs", JSON.stringify(newKOTs));
      return newKOTs;
    });
  };

  const removeUnsuccessfulKOT = (index) => {
    setStateUnsuccessfulKOTs((prev) => {
      const newKOTs = [...prev];
      newKOTs.splice(index, 1);
      sessionStorage.setItem("unsuccessfulKOTs", JSON.stringify(newKOTs));
      return newKOTs;
    });
  };

  const refreshOrders = async () => {
    try {
      const res = await axios.get(
        `/restaurants/${localStorage.getItem("restaurant")}/orders/running`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      const tableMapped = {};

      res.data.orders.forEach((order) => {
        if (!order.table) {
          return;
        }
        if (
          !orders[order.table._id] ||
          orders[order.table._id].updatedAt < order.updatedAt
        ) {
          tableMapped[order.table._id] = {
            ...order,
            new: true,
          };
        } else {
          tableMapped[order.table._id] = order;
        }
      });

      setOrders(tableMapped);

      return res;
    } catch (err) {
      logger(err);
      toast.error(err.response?.data?.message ?? "Network connection failed");
    }
  };

  const moveOrder = (from, to) => {
    setStateOrders((prev) => {
      const newOrders = { ...prev };

      const order = newOrders[from];

      delete newOrders[from];
      newOrders[to] = order;

      sessionStorage.setItem("orders", JSON.stringify(newOrders));
      return newOrders;
    });
  };

  const completeOrder = async (orderId) => {
    try {
      const res = await axios.put(
        `/restaurants/${localStorage.getItem("restaurant")}/orders/${orderId}`,
        {
          status: "completed",
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      logger(res.data);

      return res;
    } catch (err) {
      logger(err);
      toast.error(err.response?.data?.message ?? "Network connection failed");
      return false;
    }
  };

  const settleOrder = async (orderId) => {
    try {
      const res = await axios.put(
        `/restaurants/${localStorage.getItem("restaurant")}/orders/${orderId}`,
        {
          status: "settled",
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      logger(res.data);

      return res;
    } catch (err) {
      logger(err);
      toast.error(err.response?.data?.message ?? "Network connection failed");
      return false;
    }
  };

  const updateOrder = async (order) => {
    if (!order.table) {
      return;
    }
    setStateOrders((prev) => {
      let newOrders = { ...prev };
      let found = false;
      let shouldVibrate = true;
      for (let [table, ord] of Object.entries(prev)) {
        if (ord._id === order._id) {
          logger("found", table, order.table);
          found = true;
          if (order.status === "settled" || order.status === "cancelled"  || order.status === "waivedOff") {
            delete newOrders[table];
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table.name}`,
              body: `Order was ${order.status}`,
            });
          } else if (
            order.status === "completed" &&
            ord.status !== "completed"
          ) {
            order.isNew = false;
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table.name}`,
              body: `Order was billed`,
            });
            newOrders[table] = order;
          } else if (table !== order.table._id) {
            delete newOrders[table];
            order.isNew = true;
            newOrders[order.table._id] = order;
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table._id}`,
              body: `Order was moved from table ${ord.table.name} to ${order.table.name}`,
            });
          } else if (!ord.isWaiterCalled && order.isWaiterCalled) {
            newOrders[table] = order;
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table.name}`,
              body: `Waiter called on table`,
            });
          } else if (ord.isWaiterCalled && !order.isWaiterCalled) {
            newOrders[table] = order;
            notificationsContext.addNotification({
              table: order.table,
              time: Date.now(),
              title: `Table ${order.table.name}`,
              body: `Waiter responded on table`,
            });
          } else if (order.status === "running") {
            order.isNew = true;
            newOrders[table] = order;
            if ((order.kots?.length || 0) === 1) {
              notificationsContext.addNotification({
                table: order.table,
                time: Date.now(),
                title: `Table ${order.table.name}`,
                kot: order.kots[0],
                body: `New order was created from ${
                  !order.captain ? "Dine In Application" : "Captain / Cashier"
                }`,
              });
            } else {
              order.isNew = true;
              notificationsContext.addNotification({
                table: order.table,
                orderView: true,
                time: Date.now(),
				kot: order.kots.at(-1),
                title: `Table ${order.table.name}`,
                body: `A KOT was added to the order`,
              });
            }
          }
        }
      }

      if (!found) {
        order.isNew = true;
        newOrders[order.table._id] = order;
        notificationsContext.addNotification({
          table: order.table,
          time: Date.now(),
          title: `Table ${order.table.name}`,
		  kot: order.kots[0],
          body: `New order was created from ${
            order.user ? "Dine In Application" : "Captain / Cashier"
          }`,
        });
      }

      if (shouldVibrate) {
        vibrator.longTap();
      }

      sessionStorage.setItem("orders", JSON.stringify(newOrders));
      return newOrders;
    });
  };

  const clearOrders = () => {
    setOrders(null);
  };

  return (
    <Provider
      value={{
        orders,
        setOrders,
        addOrder,
        refreshOrders,
        moveOrder,
        clearOrders,
        completeOrder,
        updateOrder,
        settleOrder,
        unsuccessfulKOTs,
        addUnsuccessfulKOT,
        removeUnsuccessfulKOT,
      }}
    >
      {children}
    </Provider>
  );
};

export { OrderProvider, orders };
