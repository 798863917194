import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import logger from "../utils/logger";
import toast from "../utils/toast";

const waitlist = createContext({});

const { Provider } = waitlist;

const WaitlistProvider = ({ children }) => {
  const [waitlist, setStateWaitlist] = useState(
    JSON.parse(localStorage.getItem("waitlist")) || []
  );

  const setWaitlist = (waitlist) => {
    localStorage.setItem("waitlist", JSON.stringify(waitlist));
    setStateWaitlist(waitlist);
  };

  const refreshWaitlist = async () => {
    if (localStorage.getItem("token") === null) return;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    try {
      const res = await axios.get(
        `restaurants/${localStorage.getItem(
          "restaurant"
        )}/waitlist/timeRange?from=${today.getTime()}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      logger(res.data);

      setWaitlist(res.data);
	  return res;
    } catch (err) {
      logger(err);
      toast.error(err.response?.data?.message ?? "Network connection failed", {
        autoClose: 2000,
      });
    }
  };

  const updateWaitlistItem = (waitlistItem) => {
    setStateWaitlist((prevWaitlist) => {
      const newWaitlist = [...prevWaitlist];
      const index = newWaitlist.findIndex(
        (item) => item._id === waitlistItem._id
      );
      if (index !== -1) {
        if (
          waitlistItem.status === "cancelled" ||
          waitlistItem.status === "seated"
        ) {
          newWaitlist.splice(index, 1);
        } else {
          newWaitlist[index] = waitlistItem;
        }
      } else {
        newWaitlist.push(waitlistItem);
      }
      localStorage.setItem("waitlist", JSON.stringify(newWaitlist));
      return newWaitlist;
    });
  };



  return (
    <Provider
      value={{
        waitlist,
        setWaitlist,
        refreshWaitlist,
        updateWaitlistItem,
      }}
    >
      {children}
    </Provider>
  );
};

export { WaitlistProvider, waitlist };
