import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import Header from "../../components/Header/Header";
import { IconButton, Typography } from "@mui/material";
import { QrCode2Rounded } from "@mui/icons-material";
import QRDialog from "./QRDialog/QRDialog";
import WaitlistCard from "./WaitlistCard/WaitlistCard";
import styles from "./Waitlist.module.css";
import { waitlist } from "../../providers/WaitlistProvider";
import AddFAB from "./AddFAB/AddFAB";

const Waitlist = () => {
	const [showQRDialog, setShowQRDialog] = useState(false);

	const waitlistContext = useContext(waitlist);

	const handleStatusChange = async (id, status) => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/waitlist/${id}`,
				{
					status: status,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			logger(res.data);

			return res;
		} catch (err) {
			logger(err);
			toast.error(
				err.response?.data?.message ?? "Failed to update the waitlist"
			);
		}
	};
	useEffect(() => {

		const callFunction = waitlistContext.refreshWaitlist();
		toast.promise(callFunction, {
			pending: "Refreshing Waitlist",
			error: "Waitlist Refreshing failed",
			success: "Waitlist Refreshed Successfully",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<Header
				title="Current Waitlist"
				trailing={
					<IconButton
						onClick={() => {
							setShowQRDialog(true);
						}}
					>
						<QrCode2Rounded htmlColor="white" />
					</IconButton>
				}
			/>
			{(waitlistContext.waitlist || []).length === 0 ? (
				<Typography
					variant="h6"
					align="center"
					style={{ marginTop: "20px" }}
					color="#824500"
				>
					No waitlist yet
				</Typography>
			) : (
				<div className={styles.waitlists}>
					{(waitlistContext.waitlist || []).map((waitlist, index) => {
						return (
							<WaitlistCard
								waitlist={waitlist}
								index={index + 1}
								handleStatusChange={handleStatusChange}
							/>
						);
					})}
				</div>
			)}
			<QRDialog isOpen={showQRDialog} setIsOpen={setShowQRDialog} />
			<AddFAB />
		</div>
	);
};

export default Waitlist;
