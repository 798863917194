import { Dialog, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import vibrator from "../../../utils/vibrator";
import styles from "./banquetdialog.module.css";
import axios from "axios";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";
import commonStyles from "../../../styles.module.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { restaurant } from "../../../providers/RestaurantProvider";

const EditBanquetBookingDialog = ({ isOpen, setIsOpen, booking }) => {
	const [bookingData, setBookingData] = useState({
		name: "", phone: "", personCount: 0, date: ""
	});
	const [items, setItems] = useState([]);
	const [item, setItem] = useState("")
	const restaurantContext= useContext(restaurant)

	const hanldeDelete = (index) => {
		const updatedItems = items.filter((ite, i) => {
			return i !== index
		})
		setItems([...updatedItems])
	}
	const hanldeAdd = () => {
		if(item.trim()===""){
			return
		}
		setItems([...items, item])
		setItem("")
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (bookingData.name.trim() === "" ||
			bookingData.phone.toString().length !== 10 ||
			!bookingData.date ||
			!bookingData.personCount ||
			!items[0]) {
			toast.error("All fields are must")
			return;
		}
		try {
			const res = await axios.put(
				`restaurants/${localStorage.getItem(
					"restaurant"
				)}/banquet/${booking._id}`,
				{ ...bookingData, items: items },
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			restaurantContext.refreshBanquetsBooking()
			toast.success("Booking updated")
			setIsOpen(false)
			
		} catch (err) {
			setIsOpen(false)
            console.log(err)
			toast.error(err?.response?.data?.message ?? "something went wrong")
		}
	}

    useEffect(()=>{
            if(booking){
                setBookingData({
                    name:booking.name,
                    phone:booking.phone,
                    date:booking.date,
                    personCount:booking.personCount,
                })
                setItems([...booking.items])
            }
    },[booking])

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Create New Booking
					</Typography>
					<div className={styles.dialog_inputs}>

						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Name
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									value={bookingData.name}
									onChange={(e) => {
										setBookingData({ ...bookingData, name: e.target.value });
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Phone
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="number"
									maxLength={10}
									value={bookingData.phone}
									onChange={(e) => {
										setBookingData({ ...bookingData, phone: e.target.value });
									}}
								/>
							</GradientBorder>
						</div>
					</div>
					<div className={styles.dialog_inputs}>


						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Party Size
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="number"
									maxLength={2}
									value={bookingData.personCount}
									onChange={(e) => {
										setBookingData({
											...bookingData,
											personCount: e.target.value,
										});
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Time
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="datetime-local"
									onChange={(e) => {
										setBookingData({ ...bookingData, date: e.target.value });
									}}
								/>
							</GradientBorder>
						</div>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Items
						</Typography>
						<div className={styles.item_inputs}>

							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									value={item}
									onChange={(e) => {
										setItem(e.target.value);
									}}
								/>
							</GradientBorder>
							<button onClick={hanldeAdd} disabled={item.trim===""}>
								<AddCircleOutlineIcon />
							</button>
						</div>
					</div>
					<div className={styles.dialog_input}>
						<ul>
							{
								items.map((item, index) => {
									return <li className={styles.listed_item}>
										<DeleteForeverIcon onClick={()=>hanldeDelete(index)} />
										<span>{item}</span>
									</li>
								})
							}
						</ul>
					</div>


					<div className={styles.dialog_button_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								setIsOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							disabled={
								bookingData.name.trim()==="" ||
								bookingData.phone.toString().length!==10 ||
								!bookingData.date ||
								!bookingData.personCount ||
								!items[0] 
							}
							onClick={handleSubmit}
						>
							Update
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default EditBanquetBookingDialog;
