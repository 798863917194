import { Menu } from "@mui/material";
import React, { useContext } from "react";
import { settings } from "../../../providers/SettingsProvider";
import vibrator from "../../../utils/vibrator";
import styles from "./FilterMenu.module.css";
import check from "../../../assets/icons/check.png";

const FilterMenu = ({ anchorEl, onClose }) => {
	const settingsContext = useContext(settings);

	const showRunning =
		(settingsContext.settings.tableFilters || {}).running === undefined ||
		(settingsContext.settings.tableFilters || {}).running;
	const showEmpty =
		(settingsContext.settings.tableFilters || {}).empty === undefined ||
		(settingsContext.settings.tableFilters || {}).empty;

	return (
		<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
			<div
				className={styles.filter_row}
				onClick={() => {
					vibrator.tap();
					settingsContext.setTableFilter("running", !showRunning);
				}}
			>
				<span className={!showRunning && styles.unchecked}>
					{showRunning && <img src={check} alt="Check Icon" />}
				</span>{" "}
				Running
			</div>
			<div
				className={styles.filter_row}
				onClick={() => {
					vibrator.tap();
					settingsContext.setTableFilter("empty", !showEmpty);
				}}
			>
				<span className={!showEmpty && styles.unchecked}>
					{showEmpty && <img src={check} alt="Check Icon" />}
				</span>{" "}
				Empty
			</div>
		</Menu>
	);
};

export default FilterMenu;
