import {
	AccountCircle,
	Alarm,
	Circle,
	RecordVoiceOverRounded,
} from "@mui/icons-material";
import { Badge, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./TableCell.module.css";
import TableDialog from "../TableDialog/TableDialog";
import { settings } from "../../../providers/SettingsProvider";
import toast from "../../../utils/toast";
import vibrator from "../../../utils/vibrator";
import { cart } from "../../../providers/CartProvider";
import { reservation } from "../../../providers/ReservationProvider";
import logger from "../../../utils/logger";
import { kotContext } from "../../../providers/KotProvider";
import { restaurant } from "../../../providers/RestaurantProvider";

const TableCell = ({ order, table, justForDisplay, callback }) => {
	const navigate = useNavigate();
	const { kots } = useContext(kotContext);
	const settingsContext = useContext(settings);
	const cartContext = useContext(cart);
	const reservationContext = useContext(reservation);

	const restaurantContext = useContext(restaurant)

	const totalTax = restaurantContext.restaurant.taxes.reduce(
		(acc, item) => {
			return acc + item.value;
		},
		0
	);
	const orderTotal =(order.kots?.reduce((acc, kotId) => {
		const kot = kots[kotId];
		if (!kot) return acc;
		return kot.total + acc;
	}, 0) ?? 0);

	const totalTaxValue=(totalTax/100)	
	const orderTotalWithTax=orderTotal * (1+totalTaxValue) 


	const showRunning =
		(settingsContext.settings.tableFilters || {}).running === undefined ||
		(settingsContext.settings.tableFilters || {}).running;
	const showEmpty =
		(settingsContext.settings.tableFilters || {}).empty === undefined ||
		(settingsContext.settings.tableFilters || {}).empty;

	let overdueAlerted =
		Object.keys(order || {}).length > 0 &&
		order.kots.find((kotId) => {
			const kot = kots[kotId];
			if (!kot) {
				return false;
			}
			if (kot?.completed) {
				return false;
			}

			const now = Date.now();
			const kotTime = Date.parse(kot.createdAt);

			const diff = now - kotTime;

			const minutes = Math.max(0, Math.floor(diff / 1000 / 60));

			if (minutes >= 2) {
				return true;
			} else {
				return false;
			}
		});

	const getDuration = () => {
		const utcNow = new Date().getTime();
		const utcOrder = new Date(order.createdAt).getTime();
		const diff = utcNow - utcOrder;
		const minutes = Math.max(0, Math.floor(diff / 1000 / 60));
		const hours = Math.floor(minutes / 60);

		if (!minutes) {
			return "Just Updated";
		}

		if (minutes === 0) {
			return "Just Started";
		}
		if (hours > 0) {
			return `${hours} hr ${minutes % 60} min`;
		} else {
			return `${minutes} min`;
		}
	};

	const checkIfOverdue = () => {
		if (!order.kots || order.kots.length === 0) {
			return false;
		}

		for (let kotId of order.kots) {
			const kot = kots[kotId];
			if (!kot) {
				continue;
			}
			if (kot.completed) {
				continue;
			}

			const now = Date.now();
			const kotTime = Date.parse(kot.createdAt);

			const diff = now - kotTime;

			const minutes = Math.max(0, Math.floor(diff / 1000 / 60));

			if (minutes >= 25) {
				if (!overdueAlerted) {
					overdueAlerted = true;
					toast.warning(`A Kot at Table ${table.name} is overdue!`, {
						onClick: () => {
							navigate(`/order-details/${order._id}`);
						},
					});
					vibrator.longTap();
				}

				return true;
			}
		}

		return false;
	};

	const checkIfReserved = () => {
		if (
			!reservationContext.reservations ||
			!reservationContext.reservations[table._id]
		) {
			return false;
		}

		const now = Date.now();

		for (let reservation of reservationContext.reservations[table._id]) {
			const reservationTime = Date.parse(reservation.time);
			const blockTime = Date.parse(reservation.blockTime);

			logger(
				"Reservation",
				reservationTime,
				blockTime,
				now,
				reservationContext.reservations[table._id]
			);

			if (now >= blockTime && now <= reservationTime) {
				return true;
			}
		}

		return false;
	};

	const [time, setTime] = useState(getDuration());
	const [isOverdue, setIsOverdue] = useState(checkIfOverdue());
	const [isReserved, setIsReserved] = useState(checkIfReserved());

	useEffect(() => {
		const timer = setInterval(() => {
			setTime(getDuration());
			setIsOverdue(checkIfOverdue());
			setIsReserved(checkIfReserved());
		}, 60000);

		return () => {
			clearInterval(timer);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order]);
	useEffect(() => {
		setIsOverdue(checkIfOverdue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order]);

	const handleLongPress = () => {
		if (!justForDisplay) {
			vibrator.tap();
			setIsDialogOpen(true);
		}
	};

	const handleClick = () => {
		vibrator.tap();
		if (!justForDisplay) {
			if (cartContext.selectedTable !== table) {
				cartContext.clearCart();
			}
			cartContext.setSelectedTable(table);
			navigate("/order-building", {
				state: {
					table: table,
				},
			});
		} else {
			callback(table);
		}
	};

	let longTapTimer = null;

	const onMouseDown = () => {
		longTapTimer = setTimeout(() => {
			handleLongPress();
		}, 500);
	};

	const onMouseUp = () => {
		clearTimeout(longTapTimer);
	};

	const isRunning = order._id && order.status !== "cancelled" ? true : false;

	const [isDialogOpen, setIsDialogOpen] = useState(false);

	return (
		<>
			<span
				style={{
					display:
						(isRunning && !showRunning) || (!isRunning && !showEmpty)
							? "none"
							: "block",
				}}
			>
				<Badge
					badgeContent={
						order.isWaiterCalled ? (
							<RecordVoiceOverRounded
								htmlColor="white"
								sx={{
									backgroundColor: "#f44336",
									borderRadius: "50%",
									padding: "4px",
									fontSize: "15px",
								}}
							/>
						) : null
					}
					sx={{
						"& .MuiBadge-badge": {
							right: "100px",
							top: "8px",
						},
					}}
				>
					<GradientBorder
						gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
						width="1px"
						className={styles.table_cell_wrapper}
					>
						<div
							className={
								styles.table_cell +
								(order?._id && ((isOverdue ? ` ${styles.overdue}` : "") +
								(isReserved ? ` ${styles.reserved}` : "") +
								(" " + styles[order.status])))
							}
							onClick={handleClick}
							onMouseDown={onMouseDown}
							onMouseUp={onMouseUp}
							onTouchStart={onMouseDown}
							onTouchEnd={onMouseUp}
							onContextMenu={(e) => {
								e.preventDefault();
							}}
						>
							<div className={styles.icon_row}>
								<Alarm
									htmlColor={"transparent"}
									sx={{
										fontSize: "14px",
									}}
								/>
								<Typography
									variant="caption"
									component="div"
									sx={{
										fontSize: "0.6rem",
									}}
									color={order.new ? "#C2A186" : "transparent"}
								>
									New
								</Typography>
								<Circle
									sx={{
										fontSize: "14px",
									}}
									htmlColor={"transparent"}
								/>
							</div>
							<div>
								<Typography
									variant="caption"
									sx={{
										fontSize: "0.7rem",
									}}
									component="div"
									color={isRunning || isReserved ? "inherit" : "transparent"}
								>
									{isReserved ? "Reserved!" : time}
								</Typography>
								<Typography
									variant="h4"
									component="div"
									fontWeight={500}
									lineHeight={0.9}
									align="center"
								>
									{table.name}
								</Typography>
								<Typography
									variant="caption"
									sx={{
										fontSize: "0.7rem",
									}}
									component="div"
									color={isRunning ? "824500" : "transparent"}
									align="center"
								>
									₹{" "}
									{Math.round(orderTotalWithTax)}
								</Typography>
							</div>
						</div>
						{order?.isNew ? (
							<div className={styles.cta2_row}>
								<div className={styles.cta2}>
									NEW
									{/* <SaveRounded fontSize="small" /> */}
								</div>
							</div>
						) : null}
					</GradientBorder>
				</Badge>
			</span>
			<TableDialog
				isDialogOpen={isDialogOpen}
				setIsDialogOpen={setIsDialogOpen}
				order={order}
				table={table}
			/>
		</>
	);
};

export default TableCell;
