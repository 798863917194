import { Dialog, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import { cart } from "../../../providers/CartProvider";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./NoteDialog.module.css";
import { HighlightOffRounded } from "@mui/icons-material";
import vibrator from "../../../utils/vibrator";
import commonStyles from "../../../styles.module.css";

const NoteDialog = ({ isOpen, setIsOpen, item }) => {
	const cartContext = useContext(cart);
	const [note, setNote] = useState("");

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
					maxWidth: "none",
					width: "80%",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<div className={styles.note_wrapper}>
				<GradientBorder
					gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
					width="1px"
					className={styles.note}
				>
					<div>
						<div className={styles.note_header}>
							<HighlightOffRounded htmlColor="transparent" />
							<Typography
								variant="body1"
								component="div"
								color="#B29466"
								fontWeight={600}
							>
								Note
							</Typography>
							<span
								onClick={() => {
									vibrator.tap();
									setNote("");
									setIsOpen(false);
								}}
							>
								<HighlightOffRounded />
							</span>
						</div>
						<textarea
							placeholder="Less Spicy"
							value={note}
							onChange={(e) => {
								setNote(e.target.value);
							}}
						/>

						<div
							className={[
								styles.note_confirm,
								commonStyles.primary_button,
							].join(" ")}
							onClick={() => {
								vibrator.tap();
								cartContext.addNote(item._id, note);
								setNote("");
								setIsOpen(false);
							}}
						>
							Add Note
						</div>
					</div>
				</GradientBorder>
			</div>
		</Dialog>
	);
};

export default NoteDialog;
