import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import styles from "./Dashboard.module.css";
import { restaurant } from "../../providers/RestaurantProvider";
import { orders } from "../../providers/OrderProvider";
import { settings } from "../../providers/SettingsProvider";
import { IconButton, Typography } from "@mui/material";
import axios from "axios";
import TableCell from "./TableCell/TableCell";
import { FilterListRounded } from "@mui/icons-material";
import FilterMenu from "./FilterMenu/FilterMenu";
import vibrator from "../../utils/vibrator";
import logger from "../../utils/logger";
import { getToken } from "../../utils/firebase";
import { notifications } from "../../providers/NotificationProvider";
import { kotContext } from "../../providers/KotProvider";
import diningTableAnimation from "../../assets/lotties/dining-table.json";
import GradientText from "../../components/GradientText/GradientText";
import Lottie from "lottie-react";
import toast from "../../utils/toast";

const Dashboard = ({ justForDisplay = false, callback = () => { } }) => {

	const restaurantContext = useContext(restaurant);
	const ordersContext = useContext(orders);
	const settingsContext = useContext(settings);
	const [anchorEl, setAnchorEl] = useState(null);
	const notificationsContext = useContext(notifications);
	const { refreshKots, kots, setKots } = useContext(kotContext)
	const [loading, setLoading] = useState(true)

	const fetchOrders = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/running`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			let kotData = {};
			logger(res.data.kots, "dataKots")
			res.data.kots.forEach((kot) => {
				kotData[kot._id] = kot;
			});

			setKots(kotData)

			let orderTableWise = {};

			res.data.orders.forEach((order) => {
				if (!order.table) {
					return;
				}
				orderTableWise[order.table._id] = order;
			});
			ordersContext.setOrders(orderTableWise);
			setLoading(false)
			return res
		} catch (error) {
			logger(error);
			setLoading(false)
		}
	};

	useEffect(() => {
		if (!restaurantContext.restaurant._id) {
			logger("No restaurant");
			setLoading(false)
			return;
		}

		if (
			(localStorage.getItem("notificationsEnabled")?.toString() || "true") ===
			"true"
		) {
			logger("P1", localStorage);
			if (
				sessionStorage.getItem("fcmTokenRegistered") !== "true" &&
				!justForDisplay
			) {
				logger("P2", localStorage);
				getToken(notificationsContext.setNotificationsEnabled);
			}

		}
		if (!ordersContext.orders) {
			const callFunction = fetchOrders();
			toast.promise(callFunction, {
				pending: "Refreshing Order...",
				error: "Refreshing Order Failed",
				success: "Order Refreshed Successfully"
			});
		} else {
			setLoading(false)
		}


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restaurantContext.restaurant._id]);


	const buildTableGrid = () => {
		if (!ordersContext.orders) return <></>;

		return (
			<>
			{
					loading &&
					<div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<GradientText
							gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
							style={{ textAlign: "center", width: "100%", fontSize: "30px" }}
							text={"Loading..., Please wait!"}
						/>
						<Lottie
							animationData={diningTableAnimation}
							style={{
								width: "50%",
								minWidth: "300px",
								marginTop: "20px",
							}}
						/>
					</div>
				}
				{!loading && restaurantContext.restaurant.sections.map((section) => {
					let sectionVisibility = (settingsContext.settings.sectionVisibility ||
						{})[section.name];
					if (sectionVisibility === undefined) {
						sectionVisibility = true;
					}
					return (
						<div
							key={section.name}
							style={{
								display: sectionVisibility ? "block" : "none",
							}}
						>
							<div className={styles.section_header}>
								<Typography
									variant="subtitle1"
									component="div"
									sx={{
										marginLeft: "12px",
										color: "#824500",
									}}
								>
									{section.name}
								</Typography>
								<div className={styles.legend}>
									<div className={styles.legend_item}>
										<span
											style={{
												background:
													"linear-gradient(269.51deg, #008B59 -4.52%, #023C49 100%)",
											}}
										/>
										<Typography
											variant="caption"
											component="div"
											sx={{
												marginLeft: "4px",
												color: "#C2A186",
											}}
										>
											Running
										</Typography>
									</div>
									<div className={styles.legend_item}>
										<span
											style={{
												background:
													"linear-gradient(269.51deg, #00598B -4.52%, #260249 100%)",
											}}
										/>
										<Typography
											variant="caption"
											component="div"
											sx={{
												marginLeft: "4px",
												color: "#C2A186",
											}}
										>
											Billed
										</Typography>
									</div>
									<div className={styles.legend_item}>
										<span
											style={{
												background:
													"linear-gradient(136.53deg, #8B0000 25.67%, #D00303 79.82%)",
											}}
										/>
										<Typography
											variant="caption"
											component="div"
											sx={{
												marginLeft: "4px",
												color: "#C2A186",
											}}
										>
											Overdue
										</Typography>
									</div>
								</div>
							</div>
							<div className={styles.table_grid}>
								{section.tables.map((table) => {
									return (
										<TableCell
											key={table._id}
											order={ordersContext.orders[table._id] || {}}
											table={table}
											justForDisplay={justForDisplay}
											callback={callback}
										/>
									);
								})}
							</div>
						</div>
					);
				})}
				
			</>
		);
	};

	return (
		<div
			style={{
				backgroundColor: "#FEFAF3",
			}}
		>
			{!justForDisplay && (
				<Header
					trailing={
						<IconButton
							aria-label="filter"
							onClick={(e) => {
								vibrator.tap();
								setAnchorEl(e.currentTarget);
							}}
						>
							<FilterListRounded htmlColor="white" />
						</IconButton>
					}
				/>
			)}
			{!justForDisplay && (
				<div className={styles.header_row}>
					<Typography
						variant="h5"
						component="div"
						sx={{
							marginTop: "12px",
							marginLeft: "12px",
							color: "#824500",
							flexGrow: 1,
						}}
					>
						{restaurantContext.restaurant.name}
					</Typography>
				</div>
			)}
			{buildTableGrid()}
			<FilterMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
		</div>
	);
};

export default Dashboard;
