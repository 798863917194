import { Drawer, Typography } from "@mui/material";
import React from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import vibrator from "../../../utils/vibrator";
import styles from "./CategoryFilterSheet.module.css";

const CategoryFilterSheet = ({ menu, open, handleClose }) => {
	return (
		<Drawer
			anchor="bottom"
			open={open}
			onClose={handleClose}
			sx={{
				"& .MuiDrawer-paper": {
					width: "95%",
					borderRadius: "30px",
					left: "2.5%",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(161.3deg, #756349 31.59%, #D8B785 98.49%)"
				width="4px"
				className={styles.sheet_wrapper}
			>
				<div>
					<Typography
						variant="h5"
						component="div"
						sx={{
							textAlign: "center",
							color: "#B29466",
							margin: "10px 0",
						}}
					>
						Main Menu
					</Typography>
					<hr
						style={{
							width: "90%",
							border: "1.5px solid #B29466",
						}}
					/>
					{menu.map((category, idx) => {
						return (
							<div
								key={category.name}
								className={styles.category_row}
								onClick={() => {
									vibrator.tap();
									handleClose();
									const e = document.getElementById(category.name);
									if (e) {
										window.scrollTo({
											top: Math.max(e.offsetTop - 70, 0),
											behavior: "smooth",
										});
									}
								}}
							>
								<Typography
									variant="h6"
									component="div"
									sx={{
										textAlign: "center",
										fontWeight: 300,
									}}
								>
									{category.name}
								</Typography>
								{idx !== Object.keys(menu).length - 1 && (
									<hr
										style={{
											width: "100%",
											border: "1px solid #B29466",
										}}
									/>
								)}
							</div>
						);
					})}
				</div>
			</GradientBorder>
		</Drawer>
	);
};

export default CategoryFilterSheet;
