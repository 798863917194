import axios from "axios";
import { createContext, useState, useContext } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";
import { notifications } from "./NotificationProvider";

const restaurant = createContext({});
const { Provider } = restaurant;

const RestaurantProvider = ({ children }) => {
	const notificationsContext = useContext(notifications);

	const [restaurant, setStateRestaurant] = useState(
		JSON.parse(localStorage.getItem("restaurantData")) || {}
	);
	const [items, setStateItems] = useState(
		JSON.parse(localStorage.getItem("restaurantItems")) || {}
	);
	const [banquetsBookings, setBanquetBookings] = useState(
		 []
	);

	const setItems = (items) => {
		localStorage.setItem("restaurantItems", JSON.stringify(items));
		setStateItems(items);
	};

	const setRestaurant = (restaurant) => {
		localStorage.setItem("restaurantData", JSON.stringify(restaurant));
		setStateRestaurant(restaurant);
	};

	const updateItem = (itemID, item) => {
		setStateItems((prevItems) => {
			const newItems = { ...prevItems, [itemID]: item };
			if (!(itemID in prevItems)) {
				notificationsContext.addKitchenNotification({
					title: "New Item",
					body: `${item.name} has been added to the menu`,
				});
			} else if (item.available && !prevItems[itemID].available) {
				notificationsContext.addKitchenNotification({
					title: "Item Available",
					body: `${item.name} is now available`,
				});
			} else if (!item.available && prevItems[itemID].available) {
				notificationsContext.addKitchenNotification({
					title: "Item Unavailable",
					body: `${item.name} is now unavailable, Reason: ${
						item.unavailableReason || "Out of Stock"
					}`,
				});
			}
			localStorage.setItem("restaurantItems", JSON.stringify(newItems));
			return newItems;
		});
	};

	const refreshRestaurant = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/unmorphed`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			setRestaurant(res.data.restaurant ?? {});
			setItems(res.data.items ?? {});

			toast.success("Restaurant refreshed", {
				autoClose: 2000,
			});
			return res;
		} catch (err) {
			logger("refreshRestaurant err",err);
			toast.error(
				err.response?.data?.message ?? "Network connection failed",
				{
					autoClose: 2000,
				}
			);
		}
	};
	const refreshBanquetsBooking = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/banquet`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);


			setBanquetBookings(res.data?.bookings ?? []);

			return res
		} catch (err) {
			logger("refreshRestaurant err",err);
			toast.error(
				err.response?.data?.message ?? "Network connection failed",
				{
					autoClose: 2000,
				}
			);
		}
	};

	const updateAvailability = async (
		itemId,
		available,
		unavailableReason = undefined
	) => {
		try {
			await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/items/${itemId}`,
				{
					available,
					unavailableReason,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
		} catch (err) {
			logger(err);
			toast.error(err.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		}
	};

	const updateTable = (data) => {
		setStateRestaurant((prevRestaurant) => {
			const newRestaurant = { ...prevRestaurant };
			const sectionIndex = newRestaurant.sections.findIndex(
				(section) => section.name === data.section
			);
			const tableIndex = newRestaurant.sections[sectionIndex].tables.findIndex(
				(table) => table._id === data._id
			);
			if (tableIndex === -1) {
				newRestaurant.sections[sectionIndex].tables.push(data);
			} else {
				newRestaurant.sections[sectionIndex].tables[tableIndex] = data;
			}
			localStorage.setItem("restaurantData", JSON.stringify(newRestaurant));
			return newRestaurant;
		});
	};

	const updateCaptainCanBill = (data) => {
		setStateRestaurant((prevRestaurant) => {
			const newRestaurant = { ...prevRestaurant };
			newRestaurant.captainCanBill=data.captainCanBill;
			localStorage.setItem("restaurantData", JSON.stringify(newRestaurant));
			return newRestaurant;
		});
	};

	const removeTable = (tableID) => {
		setStateRestaurant((prevRestaurant) => {
			const newRestaurant = { ...prevRestaurant };
			const sectionIndex = newRestaurant.sections.findIndex((section) =>
				section.tables.map((table) => table._id).includes(tableID)
			);
			if (sectionIndex === -1) return newRestaurant;
			const tableIndex = newRestaurant.sections[sectionIndex].tables.findIndex(
				(table) => table._id === tableID
			);
			if (tableIndex === -1) return newRestaurant;
			newRestaurant.sections[sectionIndex].tables.splice(tableIndex, 1);
			localStorage.setItem("restaurantData", JSON.stringify(newRestaurant));
			return newRestaurant;
		});
	};

	const clearRestaurant = () => {
		setStateRestaurant({});
	};

	return (
		<Provider
			value={{
				restaurant,
				setRestaurant,
				refreshRestaurant,
				clearRestaurant,
				items,
				updateAvailability,
				setItems,
				updateItem,
				updateTable,
				removeTable,
				updateCaptainCanBill,
				refreshBanquetsBooking,
				setBanquetBookings,
				banquetsBookings
			}}
		>
			{children}
		</Provider>
	);
};

export { RestaurantProvider, restaurant };
