import React from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { Typography } from "@mui/material";
import styles from "./WaitlistCard.module.css";
import { CheckRounded, Delete } from "@mui/icons-material";
import toast from "../../../utils/toast";

const WaitlistCard = ({ index, waitlist, handleStatusChange }) => {
	const getDuration = () => {
		const now = new Date();
		const time = new Date(waitlist.createdAt);
		const diff = now - time;
		const minutes = Math.floor(diff / 60000);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		if (days > 0) {
			return `${days} day${days > 1 ? "s" : ""}`;
		} else if (hours > 0) {
			return `${hours} hour${hours > 1 ? "s" : ""}`;
		} else {
			return `${minutes} minute${minutes > 1 ? "s" : ""}`;
		}
	};

	return (
		<GradientBorder
			gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
			width="1px"
			className={styles.waitlist_wrapper}
		>
			<div className={styles.waitlist}>
				<div className={styles.waitlist_header}>
					<Typography
						variant="h6"
						component="div"
						color="#824500"
						fontWeight={600}
					>
						#{index}
					</Typography>
					<div className={styles.waitlist_actions}>
						<Delete
							onClick={() => {

								const callFunction = handleStatusChange(waitlist._id, "cancelled");
								toast.promise(callFunction, {
									pending: "Cancelling...",
									error: "Failed",
									success: "Successfully Cancelled"
								});
								// window.location.reload(true);
							}}
						/>
						<CheckRounded
							onClick={() => {
								const callFunction= handleStatusChange(waitlist._id, "seated");
								toast.promise(callFunction, {
									pending: "Seating...",
									error: "Failed",
									success: "Successfully Seated"
								});
								// window.location.reload(true);
							}}
						/>
					</div>
				</div>
				<ul className={styles.waitlist_details}>
					<li>Name: {waitlist.name || "--"}</li>
					<li>
						Contact: <a href={`tel:${waitlist.phone}`}>{waitlist.phone}</a>
					</li>
					<li>Party Size: {waitlist.personCount}</li>
					<li
						style={{
							fontWeight: 600,
						}}
					>
						Waiting Time: {getDuration()}
					</li>
				</ul>
			</div>
		</GradientBorder>
	);
};

export default WaitlistCard;
