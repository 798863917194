import React, { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { restaurant } from "../../providers/RestaurantProvider";
import { orders } from "../../providers/OrderProvider";
import { reservation } from "../../providers/ReservationProvider";
import { waitlist } from "../../providers/WaitlistProvider";
import logger from "../../utils/logger";
import { notifications } from "../../providers/NotificationProvider";
import { kotContext } from "../../providers/KotProvider";
import useAlertSound from "../../hooks/useAlertSound";
import toast from "../../utils/toast";

const SocketWrapper = ({ children }) => {
	const restaurantContext = useContext(restaurant);
	const ordersContext = useContext(orders);
	const reservationContext = useContext(reservation);
	const waitlistContext = useContext(waitlist);
	const notificationsContext = useContext(notifications);
	const {updateKot} = useContext(kotContext);
	const [socketReconnct, setSocketReconnect] = useState(false)
	const alertSounds = useAlertSound()


	useEffect(() => {
		if (!restaurantContext.restaurant?._id) {
			return;
		}

		const socket = io(
			(
				process.env.NODE_ENV === "development"
				? "http://localhost:3000"
				: 
				"https://shkspr24632.in"
			),
			{
				// transportOptions: {
				// 	polling: {
				// 	  extraHeaders: {
				// 		'x-auth-token': localStorage.getItem("token"),
				// 	  },
				// 	},
				//   }
				transports: ['websocket'],
				autoConnect: true,
				query:{token: localStorage.getItem("token")},
				reconnection: true,
				reconnectionDelay: 1000,
				reconnectionDelayMax: 5000,
				reconnectionAttempts: 99999
			}

		);

		socket.on("connect", () => {
			toast.success("socket connected");
			const roomName = "captain" + restaurantContext.restaurant?._id;
			socket.emit('join room', roomName);
			socket.on('room status', (msg) => {
				console.log(msg)
			});
		});

		socket.on("disconnect", () => {
			console.log("socket disconnected");
			setTimeout(()=>{
				setSocketReconnect(!socketReconnct)
			},2000)
		});

		socket.on(`/kot`, (data) => {
			logger("kot data=", data)
			updateKot(data)
		});
		socket.on('/order', (data) => {
			ordersContext.updateOrder(data);
			logger("orderupdated", data)
			if(data.isWaiterCalled){
				alertSounds.playKotAlert()
			}
		});

		socket.on(`/item`, (data) => {
			restaurantContext.updateItem(data._id, data);
		});

		socket.on(`/reservation`, (data) => {
			reservationContext.updateReservationItem(data);
		});

		socket.on(`/table`, (data) => {
			restaurantContext.updateTable(data);
		});

		socket.on(`/waitlist`, (data) => {
			waitlistContext.updateWaitlistItem(data);
		});

		socket.on(`/table/remove`, (data) => {
			restaurantContext.removeTable(data._id);
		});

		socket.on(`/restaurant/captainCanBill`, (data) => {
			restaurantContext.updateCaptainCanBill(data);
		});
		socket.on(`/categoryUpdated`, async () => {
			await restaurantContext.refreshRestaurant()
			window.location.reload()
		});
		socket.on(`test`, async (data) => {
			console.log("socket emit "+data)
		});

		return () => {
			socket.disconnect();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restaurantContext.restaurant?._id]);

	return <>{children}</>;
};

export default SocketWrapper;
