import axios from "axios";
import { createContext, useState, useContext, useEffect } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";

const kotContext = createContext({});
const { Provider } = kotContext;

const KotProvider = ({ children }) => {
  const [kots, setStateKots] = useState(
    JSON.parse(localStorage.getItem("kotData")) || {}
  );

  const setKots = (kotData) => {
    localStorage.setItem("kotData", JSON.stringify(kotData));
    setStateKots({ ...kotData });
  };

  const updateKot = (kot) => {
    setStateKots((prev) => {
      localStorage.setItem(
        "kotData",
        JSON.stringify({ ...prev, [kot._id]: kot })
      );
      return { ...prev, [kot._id]: kot };
    });
  };

  const refreshKots = async () => {
    try {
      const res = await axios.get(
        `/restaurants/${localStorage.getItem("restaurant")}/orders/running`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      let kotData = {};
      res.data.kots.forEach((kot) => {
        kotData[kot._id] = kot;
      });
      setKots(kotData);
      return res;
    } catch (err) {
     logger("kot err: ", err);

    }
  };

  // useEffect(() => {
  //   refreshKots();
  // }, []);

  const clearKot = () => {
    setStateKots({});
  };

  return (
    <Provider
      value={{
        kots,
        setKots,
        refreshKots,
        updateKot
      }}
    >
      {children}
    </Provider>
  );
};

export { KotProvider, kotContext };
