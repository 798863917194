import { Dialog, Typography } from "@mui/material";
import React, { useState } from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import vibrator from "../../../utils/vibrator";
import styles from "./ReservationDialog.module.css";
import axios from "axios";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";
import commonStyles from "../../../styles.module.css";

const ReservationDialog = ({ isOpen, setIsOpen, table }) => {
	const [reservation, setReservation] = useState({});

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<Typography
						variant="body1"
						component="div"
						color="#9E5E28"
						sx={{
							marginBottom: "12px",
						}}
					>
						Add Reservation - {table.name}
					</Typography>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Name
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="text"
								onChange={(e) => {
									setReservation({ ...reservation, name: e.target.value });
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Phone
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="number"
								maxLength={10}
								onChange={(e) => {
									setReservation({ ...reservation, phone: e.target.value });
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Party Size
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="number"
								maxLength={2}
								onChange={(e) => {
									setReservation({
										...reservation,
										personCount: e.target.value,
									});
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Time
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="datetime-local"
								onChange={(e) => {
									setReservation({ ...reservation, time: e.target.value });
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_input}>
						<Typography variant="body2" component="div" color="#9E5E28">
							Block Table At
						</Typography>
						<GradientBorder
							gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
							width="1px"
							className={styles.input_wrapper}
						>
							<input
								type="datetime-local"
								onChange={(e) => {
									setReservation({
										...reservation,
										blockTime: e.target.value,
									});
								}}
							/>
						</GradientBorder>
					</div>
					<div className={styles.dialog_button_row}>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								setIsOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={async () => {
								vibrator.tap();
								try {
									if (
										!reservation.name ||
										!reservation.phone ||
										!reservation.personCount ||
										!reservation.time ||
										!reservation.blockTime
									) {
										toast.error("Please fill all the fields");
										return;
									}

									if (reservation.phone.length !== 10) {
										toast.error("Please enter a valid phone number");
										return;
									}

									if (!reservation.name.match(/^[a-zA-Z ]*$/)) {
										toast.error("Please enter a valid name");
										return;
									}

									reservation.time = new Date(reservation.time).getTime();
									reservation.blockTime = new Date(
										reservation.blockTime
									).getTime();

									logger(reservation);

									const res = await axios.post(
										`restaurants/${localStorage.getItem(
											"restaurant"
										)}/reservations`,
										{ ...reservation, table: table._id },
										{
											headers: {
												"x-auth-token": localStorage.getItem("token"),
											},
										}
									);

									logger(res.data);
									toast.success("Reservation Added");
									setIsOpen(false);
								} catch (err) {
									logger(err);
									toast.error(
										err.response?.data?.message ?? "Error Adding Reservation"
									);
								}
							}}
						>
							Confirm
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default ReservationDialog;
