import React, { useState } from "react";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import styles from "./KOTitemCard.module.css";
import { Dialog, IconButton, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import vibrator from "../../utils/vibrator";
import toast from "../../utils/toast";
import axios from "axios";
import logger from "../../utils/logger";

const KOTitemCard = ({ item, kot }) => {
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

	let password = "";
	let deletionReason = "";

	const handleDelete = async () => {
		try {
			const newItems = kot.items
				.filter((i) => i.item._id !== item.item._id)
				.map((i) => {
					return {
						...i,
						item: i.item._id,
					};
				});

			logger(newItems);
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/kots/${kot._id}`,
				{
					items: newItems,
					deletion: {
						reason: deletionReason,
						item: item.item._id,
					},
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);
			return res;
		} catch (err) {
			logger(err);
			toast.error(err.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		} finally {
			setConfirmDialogOpen(false);
		}
	};

	const ConfirmDialog = () => {
		return (
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
					width="1px"
					className={styles.dialog_wrapper}
				>
					<div>
						<Typography
							variant="body1"
							component="div"
							color="#9E5E28"
							sx={{
								marginBottom: "12px",
							}}
						>
							Confirm Deletion
						</Typography>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Password
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="password"
									onChange={(e) => {
										password = e.target.value;
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_input}>
							<Typography variant="body2" component="div" color="#9E5E28">
								Reason
							</Typography>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.input_wrapper}
							>
								<input
									type="text"
									onChange={(e) => {
										deletionReason = e.target.value;
									}}
								/>
							</GradientBorder>
						</div>
						<div className={styles.dialog_button_row}>
							<GradientBorder
								gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
								width="1px"
								className={styles.cta}
							>
								<button
									onClick={() => {
										vibrator.tap();
										setConfirmDialogOpen(false);
									}}
								>
									Cancel
								</button>
							</GradientBorder>
							<GradientBorder
								gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
								width="1px"
								className={styles.cta}
							>
								<button
									onClick={() => {
										vibrator.tap();
										if (password === "st13521") {
											if (deletionReason === "") {
												toast.error("Please enter a reason", {
													autoClose: 2000,
												});
												return;
											}
											
											const callFunction = handleDelete();
											toast.promise(callFunction, {
												pending: "Updating KOT...",
												error: "Updating KOT Failed",
												success: "KOT Updated Successfully"
											});
										} else {
											toast.error("Incorrect Password", {
												autoClose: 2000,
											});
										}
									}}
								>
									Confirm
								</button>
							</GradientBorder>
						</div>
					</div>
				</GradientBorder>
			</Dialog>
		);
	};

	return (
		<>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.item_card_wrapper}
			>
				<div>
					<IconButton
						sx={{
							position: "absolute",
							top: "-8px",
							right: "-8px",
							width: "24px",
							height: "24px",
							backgroundColor: "#FF2F62",

							"&:hover": {
								backgroundColor: "#FF2F62",
							},
						}}
						onClick={() => {
							vibrator.tap();
							setConfirmDialogOpen(true);
						}}
					>
						<CloseRounded
							htmlColor="white"
							sx={{
								fontSize: "16px",
							}}
						/>
					</IconButton>
					<div className={styles.item}>
						<div className={styles.item_details}>
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={600}
							>
								{item.item.name}
							</Typography>
							<ul className={styles.property_list}>
								{(item.properties || []).map((prop) => {
									return (
										<Typography
											variant="caption"
											component="div"
											color="#824500"
											fontWeight={400}
										>
											<li key={prop.name}>
												{prop.name} : {prop?.option ? prop?.option : prop?.options?.join(", ") ?? ""}
											</li>
										</Typography>
									);
								})}
							</ul>
						</div>
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={600}
						>
							&times; {item.quantity}
						</Typography>
					</div>
					{item.note && (
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={400}
							textAlign="left"
						>
							<strong>Note: </strong> {item.note}
						</Typography>
					)}
				</div>
			</GradientBorder>
			<ConfirmDialog />
		</>
	);
};

export default KOTitemCard;
