import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import localforage from "localforage";
import { restaurant } from "../../providers/RestaurantProvider";
import { IconButton, Typography } from "@mui/material";
import styles from "./ConfigureNotifications.module.css";
import TableNumber from "./TableCell/TableCell";
import vibrator from "../../utils/vibrator";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import logger from "../../utils/logger";
import { useNavigate } from "react-router-dom";
import toast from "../../utils/toast";
let count =0;

const ConfigureNotifications = () => {
	const restaurantContext = useContext(restaurant);
	const navigate = useNavigate()
	const [count, setCount] = useState(0)

	const [subscribedTables, setSubscribedTables] = useState({});

	useEffect(() => {
		const fetchSubscribedTables = async () => {
			const subscribedTables = await localforage.getItem("subscribedTables");
			if (subscribedTables) setSubscribedTables(subscribedTables);
		};
		// logger(subscribedTables)

		fetchSubscribedTables();
	}, []);

	const SaveButton = () => {
		logger(count)
		return (
			<div
				className={styles.summary_bar}
				style={{
					maxHeight: count > 0 ? "40px" : "0px",
					opacity: count > 0 ? 1 : 0,
					transition: "0.3s",
				}}
			>
				<div
					className={styles.button}
					onClick={() => {
						toast.success("Notifications have been enabled")
						// navigate("/cart");
						setCount(0)
					}}
				>
						Update
				</div>
			</div>
		);
	};


	const toggleTable = async (table) => {
		const newSubscribedTables = { ...subscribedTables };
		newSubscribedTables[table] = !(newSubscribedTables[table] || false);
		await localforage.setItem("subscribedTables", newSubscribedTables);
		setSubscribedTables(newSubscribedTables);
		logger(newSubscribedTables)
		setCount(count+1)
		logger(count)
	};

	return (
		<div className={styles.body}>
			<Header 
				title="Configure Notifications"
				leading={
					<IconButton
						onClick={() => {
							vibrator.tap();
							// setIsOpen(false);
							navigate("/dashboard")
						}}
					>
						<ArrowBackIosNewRoundedIcon htmlColor="white" />
					</IconButton>
				}
				// leading={
				// 	<IconButton

				// 	>
				// 		<ArrowBackIosNewRoundedIcon/>
				// 	</IconButton>

				// }
				/>
			<div>
				{restaurantContext.restaurant.sections.map((section) => {
					return (
						<div key={section.name}>
							<div className={styles.section_header}>
								<Typography
									variant="subtitle1"
									component="div"
									sx={{
										marginLeft: "12px",
										color: "#824500",
									}}
								>
									{section.name}
								</Typography>
							</div>
							<div className={styles.table_grid}>
								{section.tables.map((table) => {
									return (
										<span
											onClick={() => {
												vibrator.tap();
												toggleTable(table._id);
											}}
										>
											<TableNumber
												table={table}
												isSubscribed={subscribedTables[table._id]}
											/>
										</span>
									);
								})}
							</div>
						</div>
					);
				})}
				
			</div>
			<SaveButton/>
		</div>
	);
};

export default ConfigureNotifications;
