import { Typography } from "@mui/material";
import React from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./TableCell.module.css";
import check from "../../../assets/icons/check.png";

const TableNumber = ({ table, isSubscribed }) => {
	return (
		<>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.table_cell_wrapper}
			>
				<div
					className={
						styles.table_cell + (isSubscribed ? " " + styles.checked : "")
					}
				>
					<span className={!isSubscribed && styles.unchecked}>
						{isSubscribed && <img src={check} alt="Check Icon" />}
					</span>
					<Typography
						variant="h4"
						component="div"
						color="#9E5E28"
						fontWeight={500}
						lineHeight={0.9}
						align="center"
					>
						{table.name}
					</Typography>
				</div>
			</GradientBorder>
		</>
	);
};

export default TableNumber;
