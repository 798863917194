import axios from "axios";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import logger from "../../utils/logger";
import Header from "../../components/Header/Header";
import { Typography } from "@mui/material";
import ReservationCard from "./ReservationCard/ReservationCard";
import toast from "../../utils/toast";
import styles from "./Reservations.module.css";
import { reservation } from "../../providers/ReservationProvider";

const Reservations = () => {
	const params = useParams();
	const { id: tableId } = params;

	const reservationContext = useContext(reservation);

	const handleStatusChange = async (id, status) => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/reservations/${id}`,
				{
					status: status,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);
			toast.success("Successfully updated reservation status");
		} catch (err) {
			logger(err);
			toast.error(
				err.response?.data?.message ?? "Failed to update the reservation"
			);
		}
	};

	return (
		<div>
			<div>
				<Header title="Reservations" />
				{(reservationContext.reservations[tableId] || []).length === 0 ? (
					<Typography
						variant="h6"
						align="center"
						style={{ marginTop: "20px" }}
						color="#824500"
					>
						No reservations for this table
					</Typography>
				) : (
					<div className={styles.reservations}>
						{(reservationContext.reservations[tableId] || []).map(
							(reservation, index) => {
								return (
									<ReservationCard
										reservation={reservation}
										index={index + 1}
										handleStatusChange={handleStatusChange}
									/>
								);
							}
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default Reservations;
