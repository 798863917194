import { Drawer, IconButton, List, ListItem, Switch } from "@mui/material";
import React, { useContext } from "react";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import Header from "../../components/Header/Header";
import { restaurant } from "../../providers/RestaurantProvider";
import { settings } from "../../providers/SettingsProvider";
import vibrator from "../../utils/vibrator";
import styles from "./Settings.module.css";
import commonStyles from "../../styles.module.css";
import { ArrowBackRounded } from "@mui/icons-material";

const Settings = ({ isOpen, setIsOpen }) => {
	const restaurantContext = useContext(restaurant);
	const settingsContext = useContext(settings);

	return (
		<Drawer
			anchor="bottom"
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDrawer-paper": {
					backgroundColor: "#FEFAF3",
					height: "100%",
				},
			}}
		>
			<Header
				title="Settings"
				// showDrawerIcon={false}
				leading={
					<IconButton
						onClick={() => {
							vibrator.tap();
							setIsOpen(false);
						}}
					>
						<ArrowBackRounded htmlColor="white" />
					</IconButton>
				}
			/>
			<List>
				{(restaurantContext.restaurant.sections || []).map((section) => {
					let sectionVisibility = (settingsContext.settings.sectionVisibility ||
						{})[section.name];
					if (sectionVisibility === undefined) {
						sectionVisibility = true;
					}
					return (
						<ListItem
							sx={{
								justifyContent: "center",
							}}
						>
							<GradientBorder
								gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
								width="1px"
								className={styles.setting_row}
							>
								<div>
									<div>{section.name}</div>
									<Switch
										checked={sectionVisibility}
										onChange={() => {
											settingsContext.setSectionVisibility(
												section.name,
												!sectionVisibility
											);
										}}
										sx={{
											"& .MuiSwitch-switchBase.Mui-checked": {
												color: "#9E5E28",
											},
											"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "#9E5E28",
												},
										}}
									/>
								</div>
							</GradientBorder>
						</ListItem>
					);
				})}
				{/* <ListItem
					sx={{
						justifyContent: "center",
					}}
				>
					<GradientBorder
						gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
						width="1px"
						className={styles.setting_row}
					>
						<div>
							<div>Alert on New KOT</div>
							<Switch
								checked={JSON.parse(localStorage.getItem('alertOnKot')) ? true : false}
								onChange={() => {
									const kotAlertOn  =JSON.parse(localStorage.getItem('alertOnKot'))
									localStorage.setItem('alertOnKot', kotAlertOn ? false : true)
								}}
								sx={{
									"& .MuiSwitch-switchBase.Mui-checked": {
										color: "#9E5E28",
									},
									"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
										backgroundColor: "#9E5E28",
									},
								}}
							/>
						</div>
					</GradientBorder>
				</ListItem> */}
				<ListItem
					sx={{
						justifyContent: "center",
					}}
				>
					<GradientBorder
						gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
						width="1px"
						className={[styles.setting_row, styles.clickable].join(" ")}
					>
						<div
							onClick={async () => {
								vibrator.tap();
								await restaurantContext.refreshRestaurant();
								setIsOpen(false);
							}}
						>
							Refresh Restaurant
						</div>
					</GradientBorder>
				</ListItem>

				<div
					className={[commonStyles.primary_button, styles.confirm].join(" ")}
					style={{animation:"none"}}
					onClick={() => {
						vibrator.tap();
						setIsOpen(false);
					}}
				>
					Done
				</div>
			</List>
		</Drawer>
	);
};

export default Settings;
