import React from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { Typography } from "@mui/material";
import styles from "./ReservationCard.module.css";
import { CheckRounded, Delete } from "@mui/icons-material";

const ReservationCard = ({ index, reservation, handleStatusChange }) => {
	return (
		<GradientBorder
			gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
			width="1px"
			className={styles.reservation_wrapper}
		>
			<div className={styles.reservation}>
				<div className={styles.reservation_header}>
					<Typography
						variant="h6"
						component="div"
						color="#824500"
						fontWeight={600}
					>
						#{index}
					</Typography>
					<div className={styles.reservation_actions}>
						<Delete
							onClick={() => {
								handleStatusChange(reservation._id, "cancelled");
							}}
						/>
						<CheckRounded
							onClick={() => {
								handleStatusChange(reservation._id, "seated");
							}}
						/>
					</div>
				</div>
				<ul className={styles.reservation_details}>
					<li>Name: {reservation.name || "--"}</li>
					<li>
						Contact:{" "}
						<a href={`tel:${reservation.phone}`}>{reservation.phone}</a>
					</li>
					<li>Party Size: {reservation.personCount}</li>
					<li
						style={{
							fontWeight: 600,
						}}
					>
						Time: {new Date(reservation.time).toLocaleString()}
					</li>
					<li
						style={{
							fontWeight: 600,
						}}
					>
						Table Blocks At: {new Date(reservation.blockTime).toLocaleString()}
					</li>
				</ul>
			</div>
		</GradientBorder>
	);
};

export default ReservationCard;
