import React, { useContext } from "react";
import Dashboard from "../Dashboard";
import { Dialog, DialogTitle } from "@mui/material";
import { orders } from "../../../providers/OrderProvider";
import toast from "../../../utils/toast";
import axios from "axios";
import logger from "../../../utils/logger";

const MoveTableDialog = ({ open, handleClose, order }) => {
	const ordersContext = useContext(orders);

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				sx={{
					backgroundColor: "#CFBBA6",
					color: "#824500",
				}}
			>
				Move Table
			</DialogTitle>
			<Dashboard
				justForDisplay={true}
				callback={async (table) => {
					if (ordersContext.orders[table._id]) {
						toast.error("Table already occupied", {
							autoClose: 2000,
						});
						return;
					} else {
						try {
							const res =await axios.put(
								`/restaurants/${localStorage.getItem("restaurant")}/orders/${
									order._id
								}`,
								{
									table: table._id,
								},
								{
									headers: {
										"x-auth-token": localStorage.getItem("token"),
									},
								}
							);

							toast.success("Table moved Successfully")
							handleClose();
						} catch (error) {
							logger(error);
							if(error.response?.data?.message){
								toast.error(
									error.response?.data?.message ?? "moving table failed"
								);
							}
						}
					}
				}}
			/>
		</Dialog>
	);
};

export default MoveTableDialog;
