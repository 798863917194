import kotAlert from "../assets/audio/kot_alert.aac";
import logger from "../utils/logger";


const useAlertSound = () => {
  const audio = new Audio(kotAlert);

  const playKotAlert = () => {
      audio.play().catch(err=>{
        logger("play alert err", err)
      })
  }

  return { playKotAlert }
};

export default useAlertSound;
