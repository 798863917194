import React, { useState, useContext, useEffect } from "react";
import { restaurant } from "../../providers/RestaurantProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, IconButton, Typography } from "@mui/material";
import SubCategoryCard from "./SubcategoryCard/SubCategoryCard";
import ItemCard from "./ItemCard/ItemCard";
import styles from "./OrderBuilding.module.css";
import Header from "../../components/Header/Header";
import BottomAppBar from "./BottomAppBar/BottomAppBar";
import CategoryFilterSheet from "./CategoryFilterSheet/CategoryFilterSheet";
import { ArrowBackRounded, Search } from "@mui/icons-material";
import vibrator from "../../utils/vibrator";

const OrderBuilding = () => {
	const location = useLocation();
	const table = location.state?.table || 1;
	const [isMainMenu, setIsMainMenu] = useState(true);
	const restaurantContext = useContext(restaurant);
	const [currentMenu, setCurrentMenu] = useState(
		restaurantContext.restaurant.menu || {}
	);
	const [historyStack, setHistoryStack] = useState([]);
	const [breadCrumbs, setBreadCrumbs] = useState([]);
	const [isCategorySheetOpen, setIsCategorySheetOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener("popstate", () => {
			if (historyStack.length > 0) {
				setCurrentMenu(historyStack.pop());
			}
		});

		return () => {
			window.removeEventListener("popstate", () => {
				if (historyStack.length > 0) {
					setCurrentMenu(historyStack.pop());
				}
			});
		};
	});

	const MainMenuView = () => {
		return currentMenu.map((category) => {
			return (
				<div key={category.name} id={category.name}>
					<Typography
						variant="h6"
						component="div"
						color="#824500"
						fontWeight={400}
						sx={{
							marginTop: "20px",
							marginBottom: "10px",
							marginLeft: "16px",
						}}
					>
						{category.name}
					</Typography>
					<div className={styles.card_grid}>
						{category.subCategories.map((subCategory) => {
							return (
								<SubCategoryCard
									key={subCategory._id}
									category={subCategory}
									onClick={() => {
										vibrator.tap();
										setHistoryStack([...historyStack, currentMenu]);
										setBreadCrumbs([
											...breadCrumbs,
											category.name,
											subCategory.name,
										]);
										setCurrentMenu(subCategory);
										setIsMainMenu(false);
									}}
								/>
							);
						})}
					</div>
					<div className={styles.card_grid}>
						{category.items.map((item) => {
							return (
								<ItemCard key={item} item={restaurantContext.items[item]} />
							);
						})}
					</div>
				</div>
			);
		});
	};

	const SubMenuView = () => {
		return (
			<div>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					fontWeight={400}
					sx={{
						marginTop: "20px",
						marginBottom: "10px",
						marginLeft: "16px",
					}}
				>
					{currentMenu.name}
				</Typography>
				<Breadcrumbs
					aria-label="breadcrumb"
					sx={{
						marginTop: "10px",
						marginBottom: "10px",
						marginLeft: "16px",
					}}
					separator="›"
				>
					{breadCrumbs.map((crumb, index) => {
						return (
							<Typography
								key={index}
								color="#824500"
								fontWeight={400}
								variant="caption"
								onClick={
									index === breadCrumbs.length - 1
										? null
										: () => {
												vibrator.tap();
												if (index === 0) {
													setIsMainMenu(true);
													setBreadCrumbs([]);
													setCurrentMenu(restaurantContext.restaurant.menu);
													setHistoryStack([]);
												} else {
													setBreadCrumbs(breadCrumbs.slice(0, index + 1));
													setCurrentMenu(historyStack[index]);
													setHistoryStack(historyStack.slice(0, index));
												}
										  }
								}
							>
								{crumb}
							</Typography>
						);
					})}
				</Breadcrumbs>
				<div className={styles.card_grid}>
					{currentMenu.subCategories.map((subCategory) => {
						return (
							<SubCategoryCard
								key={subCategory._id}
								category={subCategory}
								onClick={() => {
									vibrator.tap();
									setHistoryStack([...historyStack, currentMenu]);
									setBreadCrumbs([...breadCrumbs, subCategory.name]);
									setCurrentMenu(subCategory);
									setIsMainMenu(false);
								}}
							/>
						);
					})}
				</div>
				<div className={styles.card_grid}>
					{currentMenu.items.map((item) => {
						return <ItemCard key={item} item={restaurantContext.items[item]} />;
					})}
				</div>
			</div>
		);
	};

	const SearchResults = () => {
		return (
			<div>
				<Typography
					variant="h6"
					component="div"
					color="#824500"
					fontWeight={400}
					sx={{
						marginTop: "20px",
						marginBottom: "10px",
						marginLeft: "16px",
					}}
				>
					Search Results
				</Typography>
				<div className={styles.card_grid}>
					{Object.values(restaurantContext.items)
						.filter((item) =>
							item.name.toLowerCase().includes(searchQuery.toLowerCase()) && !item?.removed
						)
						.map((item) => {
							return <ItemCard key={item._id} item={item} />;
						})}
				</div>
			</div>
		);
	};

	return (
		<div
			style={{
				backgroundColor: "#FEFAF3",
			}}
		>
			<Header
				title={
					<div className={styles.search_bar}>
						<Search
							sx={{
								paddingLeft: "10px",
							}}
							htmlColor="#9E5E28"
						/>
						<input
							type="text"
							placeholder="Search"
							value={searchQuery}
							size={1}
							onChange={(e) => {
								if (e.target.value === "") {
									setIsSearching(false);
									setCurrentMenu(restaurantContext.restaurant.menu);
									setIsMainMenu(true);
									setBreadCrumbs([]);
									setHistoryStack([]);
									setSearchQuery("");
								} else {
									setIsSearching(true);
									setSearchQuery(e.target.value);
								}
							}}
						/>
					</div>
				}
			/>
			<div className={styles.body}>
				<div className={styles.top_row}>
					<IconButton
						onClick={() => {
							vibrator.tap();
							if (isSearching) {
								setIsSearching(false);
								setCurrentMenu(restaurantContext.restaurant.menu);
								setIsMainMenu(true);
								setBreadCrumbs([]);
								setHistoryStack([]);
								setSearchQuery("");
								return;
							}
							if (historyStack.length > 0) {
								const newHistoryStack = [...historyStack];
								const newBreadCrumbs = [...breadCrumbs];
								newBreadCrumbs.pop();
								const newMenu = newHistoryStack.pop();
								setHistoryStack(newHistoryStack);
								setBreadCrumbs(newBreadCrumbs);
								setCurrentMenu(newMenu);
								if (newHistoryStack.length === 0) {
									setIsMainMenu(true);
									setBreadCrumbs([]);
								}
							} else {
								navigate("/dashboard", { replace: true });
							}
						}}
					>
						<ArrowBackRounded fontSize="medium" htmlColor="#824500" />
					</IconButton>
					<Typography
						variant="h5"
						component="div"
						color="#824500"
						fontWeight={400}
					>
						Table No: {table.name}
					</Typography>
					<ArrowBackRounded
						fontSize="large"
						htmlColor="transparent"
						sx={{
							padding: "0px",
						}}
					/>
				</div>
				{isSearching ? (
					<SearchResults />
				) : isMainMenu ? (
					<MainMenuView />
				) : (
					<SubMenuView />
				)}
			</div>
			<BottomAppBar
				table={table}
				onFabClick={() => setIsCategorySheetOpen(true)}
			/>
			<CategoryFilterSheet
				open={isCategorySheetOpen}
				handleClose={() => setIsCategorySheetOpen(false)}
				menu={restaurantContext.restaurant.menu}
			/>
		</div>
	);
};

export default OrderBuilding;
