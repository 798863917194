import React, { useState, useContext } from "react";
import styles from "./Login.module.css";
import logo from "../../assets/images/shakespeare_logo.png";
import GradientText from "../../components/GradientText/GradientText";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import axios from "axios";
import toast from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { restaurant } from "../../providers/RestaurantProvider";
import vibrator from "../../utils/vibrator";
import logger from "../../utils/logger";
import commonStyles from "../../styles.module.css";
import { CircularProgress } from "@mui/material";

const Login = () => {
	const navigate = useNavigate();
	const restaurantContext = useContext(restaurant);
	const [loading, setLoading] = useState(false)

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true)
		vibrator.tap();

		if (!email || !password) {
			setLoading(false)
			toast.error("Please enter Email and Password");
			return;
		}

		try {
			const res = await axios.post("/restaurants/staff/login", {
				email,
				password,
				role: "CAPTAIN",
			});
			localStorage.setItem("token", res.data.token);
			axios.defaults.headers.common["x-auth-token"] = res.data.token;

			if (!res.data.staff.restaurant) {
				toast.error(
					"You have not been assigned to any restaurant yet, please contact your admin"
				);
				setLoading(false)
				return;
			}

			localStorage.setItem("restaurant", res.data.staff.restaurant);

			const restaurantRes = await axios.get(
				`/restaurants/${res.data.staff.restaurant}/unmorphed`
			);
		

			restaurantContext.setRestaurant(restaurantRes.data.restaurant);
			restaurantContext.setItems(restaurantRes.data.items);
			navigate("/dashboard", { replace: true });
		} catch (error) {
			logger("err from login",error);
			if (error.response?.status === 400) {
				toast.error("Invalid Email or Password");
			} else {
				toast.error(error.response?.data?.message ?? "Network connection failed");
			}
		} finally {
			setLoading(false)
		}
	};

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	return (
		<div className={styles.body}>
			<form className={styles.form}>
				<img src={logo} alt="Shakespeare Logo" />
				<GradientText
					gradient="linear-gradient(68.16deg, #824500 25.28%, #CA8126 44.43%, #A95800 63.24%)"
					text="MMV"
					style={{ fontSize: "1.8rem", marginBottom: "30px" }}
				/>
				<GradientBorder
					gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
					width="1px"
					className={styles.input_wrapper}
				>
					<input
						type="text"
						placeholder="Email"
						onChange={(e) => setEmail(e.target.value)}
					/>
				</GradientBorder>
				<GradientBorder
					gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
					width="1px"
					className={styles.input_wrapper}
				>
					<input
						type="password"
						placeholder="Password"
						onChange={(e) => setPassword(e.target.value)}
					/>
				</GradientBorder>
				<button
					className={[commonStyles.primary_button, styles.login_button, commonStyles.animate_btn].join(
						" "
					)}
					onClick={handleLogin}
				>
					Sign In
					<CircularProgress color="inherit" sx={{ marginLeft: "10px", display: loading ? "block" : "none" }} size={15} />
				</button>
				<div
					className={styles.forgot_password}
					onClick={() => {
						toast.info("Please contact your admin to reset your password");
					}}
				>
					Forgot Password?
				</div>
			</form>
			<footer>
				<a href="https://shakespeare.vision" target="_blank" rel="noreferrer">
					www.shakespeare.vision
				</a>
			</footer>
		</div>
	);
};

export default Login;
