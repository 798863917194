import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import Header from "../../components/Header/Header";
import styles from "./PendingBills.module.css";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import { Typography } from "@mui/material";

const PendingBills = () => {
	const [orders, setOrders] = useState([]);

	const fetchOrders = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/pending`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);

			setOrders(res.data);
			return res;
		} catch (error) {
			logger(error);
			toast.error(
				error.response?.data?.message ?? "Network Connection failed",
				{
					autoClose: 2000,
				}
			);
		}
	};

	useEffect(() => {
		
		const callFunction = fetchOrders();
		toast.promise(callFunction, {
			pending: "Fetching Pending Bills",
			error: "Pending Bills Fetching failed",
			success: "Successfully fetched pending bills"
		});
	}, []);

	return (
		<div className={styles.body}>
			<Header title="Pending Bills" />
			<div className={styles.orders}>
				{orders.map((order) => {
					const subtotal = order.kots?.reduce((acc, kot) => {
						return acc + kot.total;
					}, 0);
					let captains = new Set();

					for (let kot of order.kots) {
						if (kot.captain) {
							captains.add(kot.captain.name);
						}
					}

					captains = Array.from(captains).join(", ");

					return (
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.order_wrapper}
						>
							<div className={styles.order}>
								<div className={styles.order_header}>
									<Typography
										variant="h6"
										component="div"
										color="#824500"
										fontWeight={600}
									>
										Table : {order.table?.name ?? "Temporary Table"}
									</Typography>
								</div>
								<ul className={styles.order_details}>
									<li>ID: {order._id}</li>
									<li>Name: {order.user?.name || "--"}</li>
									<li>
										Contact:{" "}
										{order.user?.phone ? (
											<a href={`tel:${order.user.phone}`}>{order.user.phone}</a>
										) : (
											"--"
										)}
									</li>
									<li>Bill so far: {subtotal}</li>
									<li>Captain(s): {captains || "--"}</li>
									<li>
										Time:{" "}
										{new Date(Date.parse(order.createdAt)).toLocaleString()}
									</li>
								</ul>
							</div>
						</GradientBorder>
					);
				})}
			</div>
		</div>
	);
};

export default PendingBills;
