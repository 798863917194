import React, { useContext } from "react";
import styles from "./UnsuccessfulKOTs.module.css";
import Header from "../../components/Header/Header";
import { orders } from "../../providers/OrderProvider";
import { Typography } from "@mui/material";
import vibrator from "../../utils/vibrator";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import logger from "../../utils/logger";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../styles.module.css";
import toast from "../../utils/toast";

const UnsuccessfulKOTs = () => {
	const ordersContext = useContext(orders);
	const navigate = useNavigate();

	logger(ordersContext.unsuccessfulKOTs);

	const handleReorder = async (kot, idx) => {
		vibrator.tap();

		try {
			const data = kot.items.map((item) => {
				return {
					...item,
					item: item.item._id,
				};
			});

			const res = await axios.post(
				`/restaurants/${localStorage.getItem("restaurant")}/kots`,
				{
					table: kot.table._id,
					items: data,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);
			ordersContext.removeUnsuccessfulKOT(idx);

			navigate("/dashboard", { replace: true });
			return res;
		} catch (error) {
			logger(error);
			toast.error(error.response?.data?.message ?? "Reordering failed!");
		}
	};

	const ItemCard = ({ item }) => (
		<GradientBorder
			gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
			width="1px"
			className={styles.item_card_wrapper}
		>
			<div>
				<div className={styles.item}>
					<div className={styles.item_details}>
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={600}
						>
							{item.item.name}
						</Typography>
						<ul className={styles.property_list}>
							{(item.properties || []).map((prop) => {
								return (
									<Typography
										variant="caption"
										component="div"
										color="#824500"
										fontWeight={400}
									>
										<li key={prop.name}>
											{prop.name} : {prop?.option ? prop?.option : prop?.options?.join(", ") ?? ""}
										</li>
									</Typography>
								);
							})}
						</ul>
					</div>
					<Typography
						variant="body2"
						component="div"
						color="#824500"
						fontWeight={600}
					>
						&times; {item.quantity}
					</Typography>
				</div>
				{item.note && (
					<Typography
						variant="body2"
						component="div"
						color="#824500"
						fontWeight={400}
						textAlign="left"
					>
						<strong>Note: </strong> {item.note}
					</Typography>
				)}
			</div>
		</GradientBorder>
	);

	return (
		<div className={styles.body}>
			<Header title="Unsuccessful KOTs" />
			{ordersContext.unsuccessfulKOTs.length === 0 ? (
				<Typography
					variant="h6"
					color="#9E5E28"
					fontWeight={400}
					align="center"
				>
					No Unsuccessful KOTs
				</Typography>
			) : (
				<div className={styles.kots}>
					{ordersContext.unsuccessfulKOTs.map((kot, idx) => (
						<GradientBorder
							gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
							width="1px"
							className={styles.kot_wrapper}
						>
							<div className={styles.kot}>
								<div className={styles.kot_heading}>
									<Typography
										variant="h6"
										component="div"
										color="#9E5E28"
										align="center"
										sx={{
											fontWeight: "bold",
										}}
									>
										Table : {kot.table.name}
									</Typography>
								</div>
								<div className={styles.kots}>
									{kot.items.map((item) => (
										<ItemCard item={item} />
									))}
								</div>
								<div className={styles.cta_row}>
									<div
										className={[commonStyles.secondary_button, styles.cta].join(
											" "
										)}
										onClick={() => {
											ordersContext.removeUnsuccessfulKOT(idx);
										}}
									>
										Delete
									</div>
									<div
										className={[commonStyles.primary_button, styles.cta].join(
											" "
										)}
										onClick={() => {

											const callFunction = handleReorder(kot, idx);;
											toast.promise(callFunction, {
												pending: "Completing Order...",
												error: "Failed",
												success: "Successfully Completed Order"
											});
										}}
									>
										Reorder
									</div>
								</div>
							</div>
						</GradientBorder>
					))}
				</div>
			)}
		</div>
	);
};

export default UnsuccessfulKOTs;
