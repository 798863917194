import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import NotFound from "./pages/NotFound/NotFound";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import axios from "axios";
import Dashboard from "./pages/Dashboard/Dashboard";
import { createTheme, ThemeProvider } from "@mui/material";
import { RestaurantProvider } from "./providers/RestaurantProvider";
import { OrderProvider } from "./providers/OrderProvider";
import OrderBuilding from "./pages/OrderBuilding/OrderBuilding";
import { CartProvider } from "./providers/CartProvider";
import OrderView from "./pages/OrderView/OrderView";
import { SettingsProvider } from "./providers/SettingsProvider";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import SocketWrapper from "./components/SocketWrapper/SocketWrapper";
import PrintScreen from "./pages/PrintScreen/PrintScreen";
import { NotificationProvider } from "./providers/NotificationProvider";
import PendingBills from "./pages/PendingBills/PendingBills";
import UnsuccessfulKOTs from "./pages/UnsuccessfulKOTs/UnsuccessfulKOTs";
import ConfigureNotifications from "./pages/ConfigureNotifications/ConfigureNotifications";
import Reservations from "./pages/Reservations/Reservations";
import Waitlist from "./pages/Waitlist/Waitlist";
import { WaitlistProvider } from "./providers/WaitlistProvider";
import { ReservationProvider } from "./providers/ReservationProvider";
import { KotProvider } from "./providers/KotProvider";
import ProtectedComponent from "./components/ProtectedComponent/ProtectedComponent";
import BanquetBooking from "./pages/BanquetBooking/BanquetBooking";
import './index.css'

const token=''
const router = createBrowserRouter([
  {
    path: "/",
    element: <SplashScreen />,
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/register",
    element: <Register />,
  },
  {
    path: "/dashboard",
    element: <ProtectedComponent Component={Dashboard} token={token} />,
  },
  {
    path: "/order-building",
    element: <ProtectedComponent Component={OrderBuilding} token={token} />,
  },
  {
    path: "/order-view",
    element: <ProtectedComponent Component={OrderView} token={token} />,
  },
  {
    path: "/order-details/:id",
    element: <ProtectedComponent Component={OrderDetails} token={token} />,
  },
  {
    path: "pending-bills",
    element: <ProtectedComponent Component={PendingBills} token={token} />,
  },
  {
    path: "unsuccessful-kots",
    element: <ProtectedComponent Component={UnsuccessfulKOTs} token={token} />,
  },
  {
    path: "/waitlist",
    element: <ProtectedComponent Component={Waitlist} token={token} />,
  },
  {
    path: "reservations/:id",
    element: <ProtectedComponent Component={Reservations} token={token} />,
  },
  {
    path: "/banquets",
    element: <ProtectedComponent Component={BanquetBooking}  />,
  },
  {
    path: "/print",
    element: <ProtectedComponent Component={PrintScreen} token={token} />,
  },
  {
    path: "/configure-notifications",
    element: (
      <ProtectedComponent Component={ConfigureNotifications} token={token} />
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <NotificationProvider>
      <SettingsProvider>
        <RestaurantProvider>
          <WaitlistProvider>
            <ReservationProvider>
              <KotProvider>
                <OrderProvider>
                  <CartProvider>
                    <SocketWrapper>
                      <ThemeProvider
                        theme={createTheme({
                          typography: {
                            fontFamily: "Poppins, sans-serif",
                          },
                        })}
                      >
                        <RouterProvider router={router} />
                      </ThemeProvider>
                    </SocketWrapper>
                  </CartProvider>
                </OrderProvider>
              </KotProvider>
            </ReservationProvider>
          </WaitlistProvider>
        </RestaurantProvider>
      </SettingsProvider>
    </NotificationProvider>
    <ToastContainer theme="colored" />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
