import { Drawer, Typography, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import Header from "../../../components/Header/Header";
import PropertyOptionCard from "../PropertyOptionCard/PropertyOptionCard";
import styles from "./PropertyBottomSheet.module.css";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { ArrowBackRounded, HighlightOffRounded } from "@mui/icons-material";
import { cart } from "../../../providers/CartProvider";
import toast from "../../../utils/toast";
import vibrator from "../../../utils/vibrator";
import commonStyles from "../../../styles.module.css";
import logger from "../../../utils/logger";

const PropertyBottomSheet = ({ item, isOpen, setIsOpen }) => {
	const [selections, setSelections] = useState({});
	const [multipleSelections, setMultipleSelections] = useState({});
	const [showNote, setShowNote] = useState(false);
	let noteContent = "";
	const cartContext = useContext(cart);

	const PropertyView = () => {
		return (
			<div>
				{
					item.maxPropertySelectionsAllowed &&
					<Typography
						variant="h6"
						component="div"
						color="#824500"
						fontWeight={600}
						sx={{
							marginTop: "20px",
							marginBottom: "10px",
							marginLeft: "16px",
						}}
					>
						You can select upto {item.maxPropertySelectionsAllowed} Properties
					</Typography>
				}
				{item.properties.map((property, index) => {
					return (
						<div key={property._id}>
							{property?.options?.[0] ?
								<Typography
									variant="h6"
									component="div"
									fontSize={".97rem"}
									color="#824500"
									fontWeight={500}
									sx={{
										marginTop: "20px",
										marginBottom: "10px",
										marginLeft: "16px",
									}}
								>
									{property.name}
									{property.isMandatory ? "* " : null}
									<br />
									<span style={{ fontSize: ".9rem" }}>

										{property.hasMultipleSelection ? ` | Multiple Selection Available ` : null}
										{property.maxSelectionsAllowed ? ` | Max (${property.maxSelectionsAllowed})` : null}
										{(property.minSelectionsRequired && property.isMandatory) ? ` | Min (${property.minSelectionsRequired})` : null}
									</span>
								</Typography> : null}
							<div className={styles.card_grid}>
								{property.options.map((option) => {
									return (
										<span
											key={option._id}
											onClick={() => {
												vibrator.tap();
												if (property.hasMultipleSelection) {
													if (multipleSelections?.[property.name]?.includes(option.name)) {
														const updatedMultipleSelections = { ...multipleSelections }
														updatedMultipleSelections[property.name] = updatedMultipleSelections[property.name].filter((optionName) => {
															return optionName !== option.name
														})
														if (JSON.stringify(updatedMultipleSelections[property.name]) == "[]") {
															delete updatedMultipleSelections[property.name];
														}
														setMultipleSelections({
															...updatedMultipleSelections
														});
													} else {
														if (property.maxSelectionsAllowed) {
															if ((multipleSelections[property.name] ?? []).length >= property.maxSelectionsAllowed) {
																toast.error(`You can't select more than ${property.maxSelectionsAllowed} options`)
																return;
															}
														}
														setMultipleSelections({
															...multipleSelections,
															[property.name]: [...(multipleSelections?.[property.name] ?? []), option.name],
														});
													}
												} else {
													if (selections?.[property.name]?.includes(option.name)) {
														const updatedSelections = { ...selections }
														delete updatedSelections[property.name];
														setSelections({
															...updatedSelections
														});
													} else {
														setSelections({
															...selections,
															[property.name]: option.name,
														});
													}

												}
											}}
										>
											<PropertyOptionCard
												option={option}
												selected={
													(() => {
														if (property.hasMultipleSelection) {
															return multipleSelections[property.name]?.includes(option.name)
														} else {
															return selections[property.name] === option.name
														}
													})()
												}
											/>
										</span>
									);
								})}
							</div>
						</div>
					);
				})}
				<div
					className={[commonStyles.primary_button, styles.confirm].join(" ")}
					style={{ animation: "none" }}
					onClick={() => {
						vibrator.tap();
						for (let property of item.properties) {
							if (property.isMandatory) {
								if (property.hasMultipleSelection && (multipleSelections[property.name] ?? []).length === 0) {
									toast.error(`Property ${property.name} is mandatory`, {
										autoClose: 2000,
									});
									return;
								} else if (!property.hasMultipleSelection && !selections[property.name]) {
									toast.error(`Property ${property.name} is mandatory`, {
										autoClose: 2000,
									});
									return;
								}

								if (property.minSelectionsRequired && property.hasMultipleSelection) {
									if ((multipleSelections[property.name] ?? []).length < property.minSelectionsRequired) {
										toast.error(`You need to select atleast ${property.minSelectionsRequired} options in ${property.name} property`, {
											autoClose: 2000,
										});
										return;
									}
								}
							}
							if (property.maxSelectionsAllowed && property.hasMultipleSelection) {
								if ((multipleSelections[property.name] ?? []).length > property.maxSelectionsAllowed) {
									toast.error(`You can only select upto ${property.maxSelectionsAllowed} options in ${property.name} property`, {
										autoClose: 2000,
									});
									return;
								}
							}
						}
						console.log({ ...selections, ...multipleSelections })
						let totalProperties = Object.values({ ...selections, ...multipleSelections }).length;
						if (item.maxPropertySelectionsAllowed) {
							if (totalProperties > item.maxPropertySelectionsAllowed) {
								return toast.error(`You can only select upto ${item.maxPropertySelectionsAllowed} properties`)
							}
						}

						// if(item.minPropertySelectionsRequired){
						// 	if(totalProperties < item.minPropertySelectionsRequired){
						// 		return toast.error(`You need to select atleast ${item.minPropertySelectionsRequired} properties`)
						// 	}
						// }
						logger(multipleSelections, selections, "multipleSelections")
						setShowNote(true);
					}}
				>
					Confirm Item
				</div>
			</div>
		);
	};

	const NoteView = () => {
		return (
			<div className={styles.note_wrapper}>
				<GradientBorder
					gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
					width="1px"
					className={styles.note}
				>
					<div>
						<div className={styles.note_header}>
							<HighlightOffRounded htmlColor="transparent" />
							<Typography
								variant="body1"
								component="div"
								color="#B29466"
								fontWeight={600}
							>
								Note
							</Typography>
							<HighlightOffRounded />
						</div>
						<textarea
							placeholder="Less Spicy"
							onChange={(e) => {
								noteContent = e.target.value;
							}}
						/>
					</div>
				</GradientBorder>

				<div
					className={[commonStyles.primary_button, styles.note_confirm].join(
						" "
					)}
					onClick={() => {
						vibrator.tap();
						const allProperties = [];
						Object.keys(selections).forEach((key) => {
							allProperties.push({
								name: key,
								option: selections[key],
							})
						})
						Object.keys(multipleSelections).forEach((key) => {
							allProperties.push({
								name: key,
								options: multipleSelections[key]
							})
						});
						console.log(item)


						cartContext.addItem(
							item,
							allProperties,
							noteContent
						);
						noteContent = "";
						setSelections({});
						setMultipleSelections({});
						setShowNote(false);
						setIsOpen(false);
					}}
				>
					Confirm Item
				</div>
			</div>
		);
	};

	return (
		<Drawer
			anchor="bottom"
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDrawer-paper": {
					backgroundColor: "#FEFAF3",
					height: "100%",
				},
			}}
		>
			<Header
				title={item.name}
				leading={
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2 }}
						onClick={() => {
							vibrator.tap();
							setIsOpen(false);
						}}
					>
						<ArrowBackRounded />
					</IconButton>
				}
			/>
			{showNote ? <NoteView /> : <PropertyView />}
		</Drawer>
	);
};

export default PropertyBottomSheet;
