import { CircularProgress, IconButton, Modal, Switch, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { notifications } from "../../providers/NotificationProvider";
import GradientBorder from "../GradientBorder/GradientBorder";
import styles from "./NotificationsModal.module.css";
import { Delete } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import vibrator from "../../utils/vibrator";
import logger from "../../utils/logger";
import axios from "axios";
import toast from "../../utils/toast";
import commonStyles from "../../styles.module.css";
import { useNavigate } from "react-router-dom";

const NotificationsModal = ({ open, setOpen, isKitchen = false }) => {
	const notificationsContext = useContext(notifications);
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	logger(notificationsContext.notifications)

	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.modal_wrapper}
			>
				<div>
					<div className={styles.modal_header}>
						<Typography variant="h5" component="div" color="#9E5E28">
							Notifications
						</Typography>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{isLoading && (
								<CircularProgress
									size={25}
									sx={{
										color: "#9E5E28",
										marginRight: "8px",
									}}
								/>
							)}
							{true && (
								<CloseIcon
									sx={{
										"& .MuiSwitch-switchBase": {
											color: "#9E5E28",
										},
										"& .MuiSwitch-switchBase.Mui-checked": {
											color: "#9E5E28",
										},
										"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
											backgroundColor: "#9E5E28",
										},
									}}
									onClick={() =>
										// this.onClose
										// logger("atleast works")
										setOpen(false)
									}
								/>
								// </IconButton>

							)}
						</div>
					</div>
					<div className={styles.notifications}>
						{(isKitchen
							? notificationsContext.kitchenNotifications
							: notificationsContext.notifications
						).length === 0 ? (
							<Typography variant="body1" component="div" color="#9E5E28">
								No notifications
							</Typography>
						) : (
							(isKitchen
								? notificationsContext.kitchenNotifications
								: notificationsContext.notifications
							).map((notification, idx) => {
								return (

									<GradientBorder
										gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
										width="1px"
										className={styles.notification_wrapper}
									>

										<div className={styles.notification} style={{cursor:"pointer"}} onClick={
											() => {
												if (notification.kot) {
													navigate("/order-view", {
														state: {
															table: notification.table,
															kot:notification.kot
														}
													})
													setOpen(false)
												}
											}
										}>

											<div className={styles.notification_heading}>
												<Delete
													onClick={(e) => {
														e.stopPropagation()
														vibrator.tap();
														notificationsContext.removeNotification(idx);
													}}
												/>

												<Typography
													variant="h6"
													component="div"
													color="#9E5E28"
													sx={{
														fontWeight: "bold",
														fontSize: ".9rem"
													}}
												>
													{notification.title}
												</Typography>
											</div>
											<Typography
												variant="body1"
												component="div"
												color="#9E5E28"
												sx={{
													marginBottom: "8px",
												}}
											>
												{notification.body}
											</Typography>
											<Typography
												variant="body2"
												component="div"
												color="#9E5E28"
												align="right"
												sx={{
													width: "100%",
												}}
											>
												{new Date(notification.time).toLocaleString()}
											</Typography>
										</div>
									</GradientBorder>
								);
							})
						)}
					</div>
					<div className={styles.modal_footer}>
						<div
							className={commonStyles.primary_button}
							onClick={() => {
								vibrator.tap();
								navigate("/configure-notifications");
							}}
						>
							<Typography variant="body1" component="div">
								Configure
							</Typography>
						</div>

						<div
							className={commonStyles.primary_button}
							onClick={() => {
								vibrator.tap();
								isKitchen
									? notificationsContext.clearKitchenNotifications()
									: notificationsContext.clearNotifications();
							}}
						>
							<Typography variant="body1" component="div" fontWeight={300}>
								Clear All
							</Typography>
						</div>
					</div>
				</div>
			</GradientBorder>
		</Modal>
	);
};

export default NotificationsModal;
