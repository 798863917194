import { Dialog, List, ListItem, Slide, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./TableDialog.module.css";
import { useNavigate } from "react-router-dom";
import MoveTableDialog from "../MoveTableDialog/MoveTableDialog";
import toast from "../../../utils/toast";
import vibrator from "../../../utils/vibrator";
import axios from "axios";
import logger from "../../../utils/logger";
import ReservationDialog from "../ReservationDialog/ReservationDialog";
import { orders } from "../../../providers/OrderProvider";
import { restaurant } from "../../../providers/RestaurantProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const TableDialog = ({ order, isDialogOpen, setIsDialogOpen, table }) => {
	const navigate = useNavigate();
	const orderContext = useContext(orders)
	const restaurantContext = useContext(restaurant)

	const [moveTableDialogOpen, setMoveTableDialogOpen] = useState(false);
	const [reservationDialogOpen, setReservationDialogOpen] = useState(false);

	return (
		<>
			<Dialog
				open={isDialogOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setIsDialogOpen(false)}
				sx={{
					"& .MuiModal-backdrop": {
						backdropFilter: "blur(5px)",
					},
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						borderRadius: "28px 33px 30px 27px",
					},
				}}
			>
				<GradientBorder
					gradient="linear-gradient(171.88deg, #A46D34 -40.17%, #FBD2A7 107.03%)"
					width="1px"
					className={styles.dialog_wrapper}
				>
					<div>
						<Typography
							variant="h5"
							component="div"
							sx={{
								textAlign: "center",
								color: "#824500",
							}}
						>
							Table No: {table.name}
						</Typography>
						<hr />
						<List className={styles.dialog_list}>
							<ListItem
								onClick={() => {
									vibrator.tap();
									if (!order._id) {
										toast.info(`Table ${table.name} is not running`, {
											autoClose: 2000,
										});
									} else {
										navigate(`/order-details/${order._id}`);
									}
									setIsDialogOpen(false);
								}}
							>
								View KOT(s)
							</ListItem>
							<ListItem
								onClick={() => {
									vibrator.tap();
									if (!order._id) {
										toast.info(`Table ${table.name} is not running`, {
											autoClose: 2000,
										});
									} else {
										setMoveTableDialogOpen(true);
									}
									setIsDialogOpen(false);
								}}
							>
								Move Table
							</ListItem>
							<ListItem
								onClick={() => {
									vibrator.tap();
									setReservationDialogOpen(true);
								}}
							>
								Add Reservation
							</ListItem>
							<ListItem
								onClick={() => {
									vibrator.tap();
									navigate(`/reservations/${table._id}`);
									setIsDialogOpen(false);
								}}
							>
								View Reservations
							</ListItem>
							{
								(restaurantContext?.restaurant?.captainCanBill && order._id) &&
								<>
								<ListItem
									onClick={() => {
										vibrator.tap();
										const callFunction=orderContext.completeOrder(order._id);
										toast.promise(callFunction, {
											pending: "Completing Order...",
											error: "Failed",
											success: "Successfully Completed Order"
										});
										setIsDialogOpen(false);
									}}
								>
									Complete order
								</ListItem>
								<ListItem
									onClick={() => {
										vibrator.tap();
										
										const callFunction=orderContext.settleOrder(order._id);
										toast.promise(callFunction, {
											pending: "Settling Order...",
											error: "Failed",
											success: "Successfully Settled Order"
										});
										setIsDialogOpen(false);
									}}
								>
									Settle order
								</ListItem>
							</>}
							{order.isWaiterCalled && (
								<ListItem
									onClick={async () => {
										vibrator.tap();
										if (!order._id) {
											toast.info(`Table ${table.name} is not running`, {
												autoClose: 2000,
											});
										} else {
											try {
												const res = await axios.put(
													`/restaurants/${localStorage.getItem(
														"restaurant"
													)}/orders/${order._id}`,
													{
														isWaiterCalled: false,
													},
													{
														headers: {
															"x-auth-token": localStorage.getItem("token"),
														},
													}
												);

												logger(res.data);
											} catch (err) {
												logger(err);
												toast.error(
													err.response?.data?.message ?? "Network connection failed"
												);
											}
										}
										setIsDialogOpen(false);
									}}
								>
									Mark Attended
								</ListItem>
							)}
						</List>
					</div>
				</GradientBorder>
			</Dialog>
			<MoveTableDialog
				open={moveTableDialogOpen}
				handleClose={() => setMoveTableDialogOpen(false)}
				order={order}
			/>
			<ReservationDialog
				table={table}
				isOpen={reservationDialogOpen}
				setIsOpen={setReservationDialogOpen}
			/>
		</>
	);
};

export default TableDialog;
