// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as getFirebaseToken,
  onMessage
} from "firebase/messaging";
import logger from "./logger";
import axios from "axios";
import localforage from "localforage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const messaging = getMessaging(app);

export const getToken = async (setNotificationsEnabled) => {
  try {
    const currentToken = await getFirebaseToken(messaging, {
      vapidKey:
        "BDssKwAOUIXELI8dLbTEg9EulYQoBtwXLhpnqHpW8iEVOF1R5UfgoRTYFYT63D0nqfprLy-8gYWfORuACMuQpZo",
    });
    if (currentToken) {
      logger("current token for client: ", currentToken);

      const res = await axios.post(
        `restaurants/staff/fcmtoken/add`,
        {
          fcmToken: currentToken,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      logger("fcm res:",res);
      localStorage.setItem("fcmToken", currentToken);
      sessionStorage.setItem("fcmTokenRegistered", "true");
      setNotificationsEnabled(true);
    } else {
      logger(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    logger("An error occurred while retrieving token. ", err);
  }
};

// onMessage(messaging, async (payload) => {
//   logger("Message received", payload);
//   const subscribedTables = await localforage.getItem("subscribedTables");
//   const table = payload.data.table;
//   if (subscribedTables && !subscribedTables[table]) {
//     logger("Not subscribed to table, ignoring notification");
//     return;
//   }

//   navigator.serviceWorker
//     .getRegistration("/firebase-cloud-messaging-push-scope")
//     .then((registration) => {
//       if (registration) {
//         registration.showNotification("payload.data.title", {
//           body: "payload.data.body",
//           icon: process.env.PUBLIC_URL + "/android-icon-192x192.png",
//         });
//       }
//     });
// });
