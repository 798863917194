import { Button } from "@mui/material";
import React, { useRef, useContext } from "react";
import { cart } from "../../providers/CartProvider";
import styles from "./PrintScreen.module.css";
import html2canvas from "html2canvas";
import { io } from "socket.io-client";
import Header from "../../components/Header/Header";
import logger from "../../utils/logger";

const PrintScreen = () => {
	const printerRef = useRef(null);
	const cartContext = useContext(cart);

	const total = cartContext.getCartTotal();

	const socket = new WebSocket("ws://localhost:24632");
	socket.addEventListener("open", (ev) => {
		console.log("print server connected successfully");
	});

	const globalSocket = io("https://shkspr24632.in");

	globalSocket.on("connect", () => {
		console.log("connected to global socket");
	});

	globalSocket.on("print", (data) => {
		try {
			socket.send(JSON.stringify(data));
		} catch (err) {
			logger(err);
		}
	});

	const handlePrint = async () => {
		const canvas = await html2canvas(printerRef.current);
		document.body.appendChild(canvas);
		const imageData = canvas.toDataURL("image/png");
		const name = prompt("Enter printer name");
		socket.send(
			JSON.stringify({
				name,
				type: "usb",
				raw: imageData.split(",")[1],
			})
		);
	};

	const handleNetworkPrint = async () => {
		const canvas = await html2canvas(printerRef.current);
		document.body.appendChild(canvas);
		const imageData = canvas.toDataURL("image/png");
		const ip = prompt("Enter printer IP");
		socket.send(
			JSON.stringify({
				type: "network",
				ip,
				port: 9100,
				raw: imageData.split(",")[1],
			})
		);
	};

	const handleGlobalPrint = async () => {
		const canvas = await html2canvas(printerRef.current);
		document.body.appendChild(canvas);
		const imageData = canvas.toDataURL("image/png");
		globalSocket.emit("print", {
			type: "network",
			ip: "192.168.0.100",
			port: 9100,
			raw: imageData.split(",")[1],
		});
		globalSocket.emit("print", {
			type: "usb",
			name: "EPSON TM-m30 Receipt5",
			raw: imageData.split(",")[1],
		});
	};
	const handlePay = async () => {
		// try {
		// 	const data = {
		// 		merchantId: "PGTESTPAYUAT85",
		// 		merchantTransactionId: `test_${Date.now()}`,
		// 		amount: total * 105,
		// 		merchantUserId: "utkarshgupta2504",
		// 		redirectUrl: "https://st13521.xyz/api/phonepe",
		// 		redirectMode: "REDIRECT",
		// 		callbackUrl:
		// 			"https://webhook.site/77454a4c-6417-4f6e-a24f-c348c4b15b7c",
		// 		paymentInstrument: {
		// 			type: "PAY_PAGE",
		// 		},
		// 	};
		// 	const dataString = JSON.stringify(data);
		// 	const base64String = Base64.encode(dataString);
		// 	const hash = sha256(
		// 		base64String + "/pg/v1/pay" + process.env.REACT_APP_PHONEPE_SALT_KEY
		// 	);
		// 	const phonepeRes = await axios.post(
		// 		"https://api-preprod.phonepe.com/apis/merchant-simulator/pg/v1/pay",
		// 		{
		// 			request: base64String,
		// 		},
		// 		{
		// 			headers: {
		// 				"x-verify": hash + "###" + process.env.REACT_APP_PHONEPE_SALT_INDEX,
		// 			},
		// 		}
		// 	);
		// } catch (error) {
		// 	logger(error);
		// }
	};

	return (
		<>
			<Header />
			<div className={styles.print}>
				<article
					style={{
						paddingRight: "10px",
						paddingLeft: "10px",
						fontSize: "26px",
					}}
					ref={printerRef}
				>
					<table
						className={styles.info_table}
						style={{
							width: "100%",
						}}
					>
						<tr>
							<th style={{ fontWeight: "800" }}>
								Maredumilli - The Forest Grill and Bar
							</th>
						</tr>
					</table>
					<hr />
					<table>
						<tr style={{ fontWeight: "500" }}>Name: Utkarsh Gupta</tr>
						<tr>
							<td style={{ fontWeight: "500" }}>
								Date : {new Date(Date.now()).toLocaleDateString()}
							</td>
							<th style={{ fontWeight: "bolder" }}>Dine In</th>
						</tr>
					</table>
					<table className={styles.item_table}>
						<thead>
							<tr>
								<th>
									<span style={{ fontWeight: "bolder" }}>Item</span>
								</th>
								<th>
									<span style={{ fontWeight: "bolder" }}>Qty.</span>
								</th>
								<th>
									<span style={{ fontWeight: "bolder" }}>Price</span>
								</th>
								<th>
									<span style={{ fontWeight: "bolder" }}>Amount</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{(cartContext.items || []).map((item) => {
								return (
									<tr key={item._id}>
										<td>
											<span style={{ fontWeight: "500" }}>
												{item.item.name}
											</span>
											{(item.properties || []).map((property) => {
												return (
													<div
														style={{
															marginLeft: "10px",
															fontStyle: "italic",
															fontWeight: "500",
														}}
													>
														{property.name} : {property?.option ? property?.option : property?.options?.join(", ") ?? ""}
													</div>
												);
											})}
										</td>
										<td>
											<span style={{ fontWeight: "500" }}>{item.quantity}</span>
										</td>
										<td>
											<span style={{ fontWeight: "500" }}>
												{item.item.price.toFixed(2)}
											</span>
										</td>
										<td>
											<span style={{ fontWeight: "500" }}>
												{(item.item.price * item.quantity).toFixed(2)}
											</span>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<hr />
					<table className={styles.total_table}>
						<tr>
							<td style={{ fontWeight: "500" }}>
								Total Qty: {cartContext.getItemCount()}
							</td>
							<td style={{ fontWeight: "500" }}>Subtotal</td>
							<td style={{ fontWeight: "500" }}>{total.toFixed(2)}</td>
						</tr>
						<tr>
							<td style={{ fontWeight: "500" }}>CGST</td>
							<td style={{ fontWeight: "500" }}>2.5%</td>
							<td style={{ fontWeight: "500" }}>
								{(total * 0.025).toFixed(2)}
							</td>
						</tr>
						<tr>
							<td style={{ fontWeight: "500" }}>SGST</td>
							<td style={{ fontWeight: "500" }}>2.5%</td>
							<td style={{ fontWeight: "500" }}>
								{(total * 0.025).toFixed(2)}
							</td>
						</tr>
						<tr style={{ fontWeight: "800" }}>
							Grand Total : {(total + total * 0.05).toFixed(2)}
						</tr>
					</table>
					<hr />
					<center style={{ fontWeight: "bolder" }}>Thanks</center>
					<hr />
				</article>
			</div>
			<Button onClick={handlePrint}>Print</Button>
			<Button onClick={handleNetworkPrint}>Print IP</Button>
			<Button onClick={handleGlobalPrint}>Print Global</Button>
			<Button onClick={handlePay}>Pay</Button>
		</>
	);
};

export default PrintScreen;
