import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logger from "../../utils/logger";
import Header from "../../components/Header/Header";
import { Typography } from "@mui/material";
import BanquetCard from "./BanquetCard/BanquetCard";
import toast from "../../utils/toast";
import styles from "./banquet.module.css";
import commonStyles from '../../styles.module.css'
import { reservation } from "../../providers/ReservationProvider";
import BanquetBookingDialog from "./BanquetBookngDialog/BanquetBookigDialog";
import { restaurant } from "../../providers/RestaurantProvider";
import EditBanquetBookingDialog from "./BanquetBookngDialog/EditBanquetBookingDialog";

const BanquetBooking = () => {
    const params = useParams();
    const { id: tableId } = params;
    const [isBookingDialogOpen, setIsBookingDialogOpen] = useState(false)

    const restaurantContext = useContext(restaurant);

    const handleStatusChange = async (id, status) => {
        try {
            const res = await axios.put(
                `/restaurants/${localStorage.getItem("restaurant")}/banquet/${id}`,
                {
                    status: status,
                },
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );

            logger(res.data);
            restaurantContext.refreshBanquetsBooking()
            return res;
        } catch (err) {
            logger(err);
            toast.error(
                err.response?.data?.message ?? "Failed to update the banquet booking"
            );
        }
    };
    useEffect(() => {

        const callFunction = restaurantContext.refreshBanquetsBooking()
        toast.promise(callFunction, {
            pending: "Fetching Banquet Bookings...",
            error: "Failed to fetch Banquet booking",
            success: "Successfully Fetched Banquet Bookings"
        });

    }, [])

    return (
        <div>
            <div>
                <Header title="Banquets" />
                <div className={styles.banquets_header}>
                    <Typography
                        variant="h6"
                        color="#824500"
                    >
                        Banquets Bookings
                    </Typography>
                    <button onClick={() => setIsBookingDialogOpen(true)} className={commonStyles.primary_button}>New Booking</button>
                </div>
                {(restaurantContext.banquetsBookings || []).length === 0 ? (
                    <Typography
                        variant="h6"
                        align="center"
                        style={{ marginTop: "20px" }}
                        color="#824500"
                    >
                        No Banquets Booking available
                    </Typography>
                ) : (
                    <div className={styles.reservations}>
                        {(restaurantContext.banquetsBookings || [])
                            .filter((item) => item.status === "Pending")
                            .map(
                                (booking, index) => {
                                    return (
                                        <BanquetCard
                                            booking={booking}
                                            index={index + 1}
                                            handleStatusChange={handleStatusChange}
                                        />
                                    );
                                }
                            )}
                    </div>
                )}
            </div>
            <BanquetBookingDialog
                isOpen={isBookingDialogOpen}
                setIsOpen={setIsBookingDialogOpen}
            />


        </div>
    );
};

export default BanquetBooking;
