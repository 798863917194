import { toast as reactToast } from "react-toastify";

const defaultToastOptions = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: false,
	draggable: true,
	progress: undefined,
	theme: "colored",
	className:"custom-toast"
};

const toastOptions = {
	success: {
	  style: {
		background: '#07BC0C',
		color: 'white',
		borderRadius:"12px"
	},
},
error: {
	  style: {
		  background: '#F40003',
		  color: 'white',
		  borderRadius:"12px"
		},
	},
	pending: {
		render: 'Promise is pending',
		style: {
			background: '#FFEEB3',
			color: '#9E6F21',
			borderRadius:"12px"
	  },
	},
  };
function getToast(obj){
	let res={}
	Object.keys(obj, {}).map(key=>{
		let option=toastOptions[key]
		option.render=obj[key]
		res[key]=option
	})
	return res;
}

const toast = {
	success: (message, options = {}) => {
		reactToast.success(message, { ...defaultToastOptions, ...options });
	},
	error: (message, options = {}) => {
		reactToast.error(message, { ...defaultToastOptions, ...options });
	},
	warning: (message, options = {}) => {
		reactToast.warning(message, { ...defaultToastOptions, ...options });
	},
	info: (message, options = {}) => {
		reactToast.info(message, { ...defaultToastOptions, ...options });
	},
	promise: (callFunction, options = {}) => {
		reactToast.promise(callFunction, { ...defaultToastOptions, ...getToast(options)});
	},
	default: (message, options = {}) => {
		reactToast(message, { ...defaultToastOptions, ...options });
	},
};

export default toast;
