import React, { useContext, useEffect, useState } from "react";
import styles from "./QRDialog.module.css";
import { restaurant } from "../../../providers/RestaurantProvider";
import { Dialog, Typography } from "@mui/material";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import qrious from "qrious";
import commonStyles from "../../../styles.module.css";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";

const QRDialog = ({ isOpen, setIsOpen }) => {
	const restaurantContext = useContext(restaurant);
	const [qr, setQR] = useState(null);

	useEffect(() => {
		if (restaurantContext.restaurant?._id) {
			const qr = new qrious({
				value: `https://dinein.shakespeare.vision/waitlist/${restaurantContext.restaurant._id}/${restaurantContext.restaurant.waitlistCode}`,
				// value: `http://localhost:5001/waitlist/${restaurantContext.restaurant._id}/${restaurantContext.restaurant.waitlistCode}`,
				size: 320,
				padding: 40,
			});
			setQR(qr);
		}
	}, [
		restaurantContext.restaurant?.waitlistCode,
		restaurantContext.restaurant?._id,
	]);

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
					maxWidth: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(229.4deg, rgba(255, 255, 255, 0.77) -16.85%, #9E5E28 153.96%)"
				width="1px"
				className={styles.qr_dialog}
			>
				<div>
					<Typography
						variant="h6"
						component="div"
						color="#B29466"
						fontWeight={400}
					>
						Waitlist QR Code
					</Typography>
					{qr && <img src={qr.toDataURL()} alt="QR Code" />}
					<div className={styles.cta_row}>
						<button
							className={commonStyles.primary_button}
							onClick={async () => {
								logger("share in navi",navigator);
								if(!('share' in navigator)){
									toast.error("This browser does not support sharing.")
								}
								const blob = await (await fetch(qr.toDataURL())).blob();
								navigator.share({
									title: "DineIn Waitlist",
									text: "Join the waitlist",
									files: [new File([blob], "qr.png", { type: "image/png" })],
								});
							}}
						>
							Share
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default QRDialog;
