import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import KOTitemCard from "./KOTitemCard";
import styles from "./OrderDetails.module.css";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import { orders } from "../../providers/OrderProvider";
import vibrator from "../../utils/vibrator";
import { Alarm, PrintRounded } from "@mui/icons-material";
import commonStyles from "../../styles.module.css";
import { kotContext } from "../../providers/KotProvider";
import { toast } from "react-toastify";
import axios from "axios";

const OrderDetails = () => {
	const params = useParams();
	const { id: orderId } = params;

	const [order, setOrder] = useState({});
	const [loading, setLoading] = useState(true);

	const [isCompleting, setIsCompleting] = useState(false);

	const navigate = useNavigate();

	const ordersContext = useContext(orders);
	const { kots } = useContext(kotContext)

	const handleComplete = async () => {
		vibrator.tap();
		setIsCompleting(true);
		if (await ordersContext.completeOrder(orderId)) {
			navigate("/dashboard", { replace: true });
		}
		setIsCompleting(false);
	};

	const fetchOrder = () => {
		if (!ordersContext.orders) {
			return;
		}

		const order = Object.values(ordersContext?.orders ?? {}).find(
			(order) => order._id === orderId
		);

		if (order) {
			setOrder(order);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOrder();
	}, [orderId, ordersContext.orders]);

	const handlePrinting = async (kot) => {
		try {
			const res = await axios.get(
				`restaurants/${localStorage.getItem("restaurant")}/kots/${kot._id}/print`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			return res;
		} catch (err) {
			console.log(err)
			throw new Error("Error")
		}
	}

	return (
		<>
			<Header title="KOT(s)" />
			{loading ? (
				<></>
			) : (
				<div className={styles.body}>
					<div>
						<Typography
							variant="h4"
							component="div"
							color="#824500"
							fontWeight={400}
							sx={{
								paddingTop: "20px",
								marginBottom: "10px",
								marginLeft: "16px",
							}}
						>
							Table No : {order?.table?.name}
						</Typography>

					</div>
					<div className={styles.legend}>
						<div className={styles.legend_item}>
							<span
								style={{
									background:
										"linear-gradient(#06DCCF, #389E7A)",
								}}
							/>
							<Typography
								variant="caption"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								User
							</Typography>
						</div>
						<div className={styles.legend_item}>
							<span
								style={{
									background:
										"linear-gradient(#7E06DC, #63B4FF)",
								}}
							/>
							<Typography
								variant="caption"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								Captain
							</Typography>
						</div>
						<div className={styles.legend_item}>
							<span
								style={{
									background:
										"linear-gradient(270.69deg, rgba(255, 168, 168, 0.77) -6.58%, #ED0000 153.04%)",
								}}
							/>
							<Typography
								variant="caption"
								component="div"
								sx={{
									marginLeft: "4px",
									color: "#C2A186",
								}}
							>
								Overdue
							</Typography>
						</div>
					</div>

					{(order?.kots ?? []).map((kotId, index) => {
						const kot = kots[kotId]
						const now = Date.now();
						const kotTime = Date.parse(kot?.createdAt ?? new Date());

						const diff = now - kotTime;

						const minutes = Math.max(0, Math.floor(diff / 1000 / 60));

						const isUser = !kot?.captain;
						const overdue = minutes >= 25;

						return (
							<GradientBorder
								className={styles.kot_wrapper}
								width="2px"
								gradient={
									overdue
										? "linear-gradient(270.69deg, rgba(255, 168, 168, 0.77) -6.58%, #ED0000 153.04%)"
										: isUser
											? "linear-gradient(#06DCCF, #389E7A)"
											: "linear-gradient(#7E06DC, #63B4FF)"
								}
							>
								<div>
									<div className={styles.kot_heading}>
										<Typography
											variant="h5"
											component="span"
											color="#824500"
											fontWeight={400}
											sx={{
												marginLeft: "8px",
											}}
										>
											KOT #{index + 1}
										</Typography>
										<Typography
											variant="body"
											component="span"
											color="#824500"
											fontWeight={400}
											sx={{
												marginLeft: "8px",
											}}
										>
											[{minutes} mins]
										</Typography>
										{overdue && (
											<Alarm
												htmlColor="#824500"
												sx={{
													marginLeft: "12px",
												}}
											/>
										)}
										<PrintRounded sx={{marginLeft:"20px"}} className={commonStyles.animate_btn} htmlColor="#824500" onClick={() => toast.promise(
											handlePrinting(kot),
											{
												pending: "Printing...",
												error: "Printing failed",
												success: "Added to print queue"
											}
										)} />
									</div>
									<div className={styles.kot_item_list}>
										{(kot?.items ?? []).map((item) => {
											return <KOTitemCard item={item} kot={kot} />;
										})}
									</div>
								</div>
							</GradientBorder>
						);
					})}
					<Typography
						variant="h5"
						component="div"
						color="#824500"
						fontWeight={400}
						sx={{
							paddingTop: "20px",
							paddingBottom: "20px",
							marginLeft: "16px",
						}}
					>
						Total :{" "}
						{order.kots.reduce((acc, kotId) => {
							const kot = kots[kotId]
							return acc + kot?.total ?? 0;
						}, 0)}
					</Typography>
					<div className={styles.cta_row}>
						<div
							className={commonStyles.secondary_button}
							onClick={() => {
								vibrator.tap();
								navigate("/dashboard", { replace: true });
							}}
						>
							Back
						</div>
						{/* {order.status === "running" && (
							<div
								onClick={isCompleting ? null : handleComplete}
								className={commonStyles.primary_button}
							>
								{isCompleting ? (
									<CircularProgress
										color="inherit"
										size={24}
										sx={{
											padding: "0px 22px",
										}}
									/>
								) : (
									"Complete"
								)}
							</div>
						)} */}
					</div>
				</div>
			)}
		</>
	);
};

export default OrderDetails;
