import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

import logger from "../utils/logger";
import toast from "../utils/toast";

const reservation = createContext({});
const { Provider } = reservation;

const ReservationProvider = ({ children }) => {
	const [reservations, setStateReservations] = useState(
		JSON.parse(localStorage.getItem("reservations")) || {}
	);

	const setReservations = (reservations) => {
		localStorage.setItem("reservations", JSON.stringify(reservations));
		setStateReservations(reservations);
	};

	const refreshReservations = async () => {
		if (localStorage.getItem("token") === null) return;
		try {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			const res = await axios.get(
				`restaurants/${localStorage.getItem(
					"restaurant"
				)}/reservations/timeRange?from=${today.getTime()}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			const tableMappedReservations = {};

			res.data.forEach((reservation) => {
				if (!tableMappedReservations[reservation.table]) {
					tableMappedReservations[reservation.table] = [];
				}
				tableMappedReservations[reservation.table].push(reservation);
			});

			setReservations(tableMappedReservations);
		} catch (err) {
			logger(err);
			toast.error(
				err.response?.data?.message ?? "Network connetcion failed",
				{
					autoClose: 2000,
				}
			);
		}
	};

	const updateReservationItem = (reservationItem) => {
		setStateReservations((prevReservations) => {
			const newReservations = { ...prevReservations };
			const table = reservationItem.table;
			if (!newReservations[table]) {
				newReservations[table] = [];
			}
			const index = newReservations[table].findIndex(
				(item) => item._id === reservationItem._id
			);

			if (index === -1) {
				newReservations[table].push(reservationItem);
			} else {
				newReservations[table][index] = reservationItem;
			}
			localStorage.setItem("reservations", JSON.stringify(newReservations));
			return newReservations;
		});
	};

	useEffect(() => {
		refreshReservations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Provider
			value={{
				reservations,
				setReservations,
				refreshReservations,
				updateReservationItem,
			}}
		>
			{children}
		</Provider>
	);
};

export { reservation, ReservationProvider };
