import React from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./PropertyOptionCard.module.css";

const PropertyOptionCard = ({ option, selected }) => {
	return (
		<GradientBorder
			gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
			width="1px"
			className={styles.card_wrapper}
		>
			<div style={{textAlign:"center", padding:"5px"}} aria-selected={selected ? "true" : "false"} >
				{option.name}
				<span> + {option.price} /-</span>
			</div>
		</GradientBorder>
	);
};

export default PropertyOptionCard;
