import { CircularProgress, Dialog } from "@mui/material";
import React, { useState, useContext } from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import styles from "./UserDetailInput.module.css";
import commonStyles from "../../../styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import vibrator from "../../../utils/vibrator";
import { cart } from "../../../providers/CartProvider";
import { orders } from "../../../providers/OrderProvider";
import axios from "axios";
import logger from "../../../utils/logger";
import toast from "../../../utils/toast";

const UserDetailInput = ({ isOpen, setIsOpen, table }) => {
	const [user, setUser] = useState({});
	const [pax, setPax] = useState(1);
	const [isConfirming, setIsConfirming] = useState(false);

	const cartContext = useContext(cart);
	const ordersContext = useContext(orders);

	const navigate = useNavigate();
	const location = useLocation();

	const handleConfirm = async () => {
		vibrator.tap();


		try {
			setIsConfirming(true);
			const data = cartContext.items.map((item) => {
				return {
					...item,
					item: item.item._id,
				};
			});

			const res = await axios.post(
				`/restaurants/${localStorage.getItem("restaurant")}/kots`,
				{
					table: location.state.table._id,
					items: data,
					user,
					personCount: pax,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);
			cartContext.clearCart();
			setIsOpen(false);

			navigate("/dashboard", { replace: true });
			return res;
		} catch (error) {
			logger(error);
			ordersContext.addUnsuccessfulKOT({
				table: location.state.table,
				items: cartContext.items,
				user: user,
				pax: pax,
			});
			toast.error(
				(error.response?.data?.message ?? "Error confirming order") +
				", added to unsuccessful KOT"
			);
			cartContext.clearCart();
			navigate("/dashboard", { replace: true });
		} finally {
			setIsConfirming(false);
		}
	};

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setIsOpen(false);
			}}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={styles.dialog_wrapper}
			>
				<div>
					<div className={styles.inputs}>
						<div className={styles.input}>
							<label htmlFor="name">Name</label>
							<input
								className={styles.input1}
								type="text"
								id="name"
								name="name"
								onChange={(e) => {
									setUser((prev) => {
										return { ...prev, name: e.target.value };
									});
								}}
								value={user.name}
							/>
						</div>
						<div className={styles.input}>
							<label htmlFor="phone">Phone</label>
							<input
								className={styles.input2}
								type="text"
								id="phone"
								name="phone"
								maxLength={10}
								onChange={(e) => {
									setUser((prev) => {
										return { ...prev, phone: e.target.value };
									});
								}}
								value={user.phone}
							/>
						</div>
						<div className={styles.input}>
							<label htmlFor="pax">No. of People</label>
							<input
								type="number"
								id="pax"
								name="pax"
								onChange={(e) => {
									setPax(e.target.value);
								}}
								value={pax}
								style={{
									width: "40px",
									textAlign: "center",
								}}
							/>
						</div>
					</div>
					<div className={styles.submit}>
						<button
							className={commonStyles.primary_button}
							onClick={(e) => {
								if (cartContext.items.length === 0) {
									toast.error("Cart is empty!", {
										autoClose: 2000,
									});
									return;
								}

								if (user.phone && user.phone.length !== 10) {
									toast.error("Invalid phone number!", {
										autoClose: 2000,
									});
									return;
								}

								if (!user.name.match(/^[a-zA-Z ]*$/)) {
									toast.error("Invalid name!", {
										autoClose: 2000,
									});
									return;
								}
								const callFunction = handleConfirm(e);
								toast.promise(callFunction, {
									pending: "Confirming...",
									error: "Creating KOT Failed",
									success: "KOT Created Successfully"
								});
							}}
						>
							{isConfirming ? (
								<CircularProgress
									color="inherit"
									size={18}
									sx={{
										padding: "0px 12px",
									}}
								/>
							) : (
								"Submit"
							)}
						</button>
					</div>
				</div>
			</GradientBorder>
		</Dialog>
	);
};

export default UserDetailInput;
