import {
	ArrowBackRounded,
	RemoveShoppingCartRounded,
} from "@mui/icons-material";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import Header from "../../components/Header/Header";
import { cart } from "../../providers/CartProvider";
import { orders } from "../../providers/OrderProvider";
import toast from "../../utils/toast";
import vibrator from "../../utils/vibrator";
import styles from "./OrderView.module.css";
import logger from "../../utils/logger";
import commonStyles from "../../styles.module.css";
import UserDetailInput from "../OrderBuilding/UserDetailInput/UserDetailInput";
import { kotContext } from "../../providers/KotProvider";

const OrderView = () => {
	const location = useLocation();
	const cartContext = useContext(cart);
	const ordersContext = useContext(orders);
	const { kots } = useContext(kotContext)
	const myRef = useRef(null);

	const navigate = useNavigate();

	const [isConfirming, setIsConfirming] = useState(false);
	const [isUserDetailsOpen, setIsUserDetailsOpen] = useState(false);
	useEffect(()=>{
		if(myRef.current){
			myRef.current.scrollIntoView()
		}
	},[location.state.kot, myRef])

	const handleConfirm = async () => {
		vibrator.tap(); 
		

		try {
			setIsConfirming(true);
			const data = cartContext.items.map((item) => {
				return {
					...item,
					item: item.item._id,
				};
			});

			const res = await axios.post(
				`/restaurants/${localStorage.getItem("restaurant")}/kots`,
				{
					table: location.state.table._id,
					items: data,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			cartContext.clearCart();

			navigate("/dashboard", { replace: true });
			return res;
		} catch (error) {
			logger("error", error.response.data.message);
			ordersContext.addUnsuccessfulKOT({
				table: location.state.table,
				items: cartContext.items,
			});
			toast.error(error.response?.data?.message ?? "");
			cartContext.clearCart();
			navigate("/dashboard", { replace: true });
		} finally {
			setIsConfirming(false);
		}
	};

	const OrderItemCard = ({ item, readOnly, animate=false }) => {
		const quantity = cartContext.getQuantity(item.item._id, item.properties);

		return (
			<GradientBorder
				gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
				width="1px"
				className={`${styles.item_card_wrapper} ${animate ? styles.blink_animation : ""}`}
			>
				<div>
					<div className={styles.item}>
						<div className={styles.item_details}>
							<Typography
								variant="body2"
								component="div"
								color="#824500"
								fontWeight={600}
							>
								{item.item.name}
							</Typography>
							<ul className={styles.property_list}>
								{(item.properties || []).map((prop) => {
									return (
										<Typography
											variant="caption"
											component="div"
											color="#824500"
											fontWeight={400}
										>

											<li key={prop.name}>
												{prop.name} : {prop?.option ? prop?.option : prop?.options?.join(", ") ?? ""}
											</li>
										</Typography>
									);
								})}
							</ul>
						</div>
						{
							!readOnly ?
								<>
									<div className={styles.counter}>
										<div
											className={styles.counter_button}
											onClick={
												quantity === 0
													? undefined
													: () => {
														vibrator.tap();
														cartContext.subtractItem(item.item, item.properties);
													}
											}
										>
											-
										</div>
										<div className={styles.counter_value}>{quantity}</div>
										<div
											className={styles.counter_button}
											onClick={() => {
												vibrator.tap();
												cartContext.addItem(item.item, item.properties);
											}}
										>
											+
										</div>
									</div>
								</> :
								<div className={styles.counter} style={{ padding: "5px" }}>
									x {item.quantity}
								</div>

						}
					</div>
					{item.note && (
						<Typography
							variant="body2"
							component="div"
							color="#824500"
							fontWeight={400}
							textAlign="left"
						>
							<strong>Note: </strong> {item.note}
						</Typography>
					)}
				</div>
			</GradientBorder >
		);
	};

	return (
		<>
			<Header title={"Verify Order"} />
			<div>
				<div class={styles.header_row}>
					<IconButton
						onClick={() => {
							vibrator.tap();
							navigate("/order-building", {
								replace: true,
								state: location.state,
							});
						}}
					>
						<ArrowBackRounded fontSize="large" htmlColor="#814500" />
					</IconButton>
					<Typography
						variant="h6"
						component="div"
						color="#824500"
						fontWeight={500}
						sx={{
							marginTop: "10px",
							marginBottom: "10px",
						}}
					>
						Table No : {location.state.table.name || 1}
						{
							ordersContext?.orders?.[location.state.table._id] &&
							<>
							(Bill No: {ordersContext?.orders?.[location.state.table._id]?.orderNumber})
							</>
						}
					</Typography>
					<IconButton
						onClick={() => {
							vibrator.tap();
							cartContext.clearCart();
						}}
					>
						<RemoveShoppingCartRounded fontSize="large" htmlColor="#814500" />
					</IconButton>
				</div>
				<div className={styles.item_list}>
					{cartContext.items.map((item) => {
						return <OrderItemCard key={item._id} item={item} />;
					})}
				</div>
			</div>
			{
				cartContext.items.length !== 0 &&
					<div
						className={[styles.confirm_wrapper, commonStyles.primary_button].join(
							" "
						)}
						style={{ animation: "none" }}
						onClick={(e) => {
							if (isConfirming) return;
							if (cartContext.items.length === 0) {
								toast.error("Cart is empty!", {
									autoClose: 2000,
								});
								return;
							}
							if (!ordersContext.orders[location.state.table._id]?.user) {
								setIsUserDetailsOpen(true);
								return;
							}
							const callFunction = handleConfirm(e);
							toast.promise(callFunction, {
								pending: "Confirming...",
								error: "Creating KOT Failed",
								success: "KOT Created Successfully"
							});
						}}
					>
						{isConfirming ? (
							<CircularProgress
								color="inherit"
								size={24}
							// sx={{
							// 	padding: "0px 48px",
							// }}
							/>
						) : (
							"Confirm Order"
						)}
					</div>
			}

			<div className={styles.item_list}>

				{
					(ordersContext?.orders?.[location.state.table._id]?.kots || []).map((kotId, index) => {
						const kot = kots[kotId]
						return <>
							<h3 style={{ textAlign: "left", width: "95%", color: "#9E5E28" }} ref={location.state.kot===kotId ? myRef : null}>Kot #{index + 1}</h3>
							{
								kot?.items.map((item, index) => {
									return <OrderItemCard animate={location.state.kot===kotId} key={item._id} item={item} readOnly />;
								})
							}
						</>
					})
				}
			</div>
			<UserDetailInput
				isOpen={isUserDetailsOpen}
				setIsOpen={setIsUserDetailsOpen}
				table={location.state.table}
			/>
		</>
	);
};

export default OrderView;
