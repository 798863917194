import React, { useState } from "react";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { Typography } from "@mui/material";
import styles from "./BanquetCard.module.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CheckRounded, Delete } from "@mui/icons-material";
import EditBanquetBookingDialog from "../BanquetBookngDialog/EditBanquetBookingDialog";

const BanquetCard = ({ index, booking, handleStatusChange }) => {
	const [isEditBookingDialogOpen, setIsEditBookingDialogOpen] = useState(false)

	return (
		<GradientBorder
			gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
			width="1px"
			className={styles.reservation_wrapper}
		>
			<div className={styles.reservation}>
				<div className={styles.reservation_header}>
					<Typography
						variant="h6"
						component="div"
						color="#824500"
						fontWeight={600}
					>
						#{index}
					</Typography>
					<div className={styles.reservation_actions}>
						<EditOutlinedIcon
							onClick={() => {
								setIsEditBookingDialogOpen(true);
							}}
						/>
						<Delete
							onClick={() => {
								handleStatusChange(booking._id, "Cancelled");
							}}
						/>
						<CheckRounded
							onClick={() => {
								handleStatusChange(booking._id, "Completed");
							}}
						/>
					</div>
				</div>
				<ul className={styles.reservation_details}>
					<li>Name: {booking.name || "--"}</li>
					<li>
						Contact:{" "}
						<a href={`tel:${booking.phone}`}>{booking.phone}</a>
					</li>
					<li>Party Size: {booking.personCount}</li>
					<li
						style={{
							fontWeight: 600,
						}}
					>
						Time: {new Date(booking.date).toLocaleString()}
					</li>
					<li style={{
							fontWeight: 600,
						}}>Items</li>
					{
						booking.items.map((item) => {
							return <li>{item}</li>
						})
					}
				</ul>
			</div>
			<EditBanquetBookingDialog
            booking={booking}
            isOpen={isEditBookingDialogOpen}
            setIsOpen={setIsEditBookingDialogOpen}
            />
		</GradientBorder>

	);
};

export default BanquetCard;
