import React, { useEffect, useContext } from "react";
import styles from "./SplashScreen.module.css";
import logo from "../../assets/images/shakespeare_logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { restaurant } from "../../providers/RestaurantProvider";
import logger from "../../utils/logger";
import toast from '../../utils/toast'

const SplashScreen = () => {
	const navigate = useNavigate();
	const restaurantContext = useContext(restaurant);

	const initialise = async () => {
		if (!localStorage.getItem("token")) {
			navigate("/auth/login", { replace: true });
			return;
		}

		axios.defaults.headers.common["x-auth-token"] =
			localStorage.getItem("token");

		try {
			const res = await axios.get("/restaurants/staff/me");

			if (!res.data.restaurant) {
				navigate("/auth/login", { replace: true });
				return;
			}

			localStorage.setItem("restaurant", res.data.restaurant);

			const restaurantRes = await axios.get(
				`/restaurants/${res.data.restaurant}/unmorphed`
			);

			restaurantContext.setRestaurant(restaurantRes.data.restaurant);
			restaurantContext.setItems(restaurantRes.data.items);

			navigate("/dashboard", { replace: true });
		} catch (error) {
			logger(error);
			if (navigator.onLine) {
				navigate("/auth/login", { replace: true });
			}else{
				toast.error("Network Connection Failed")
			}
			return;
		}
	};

	useEffect(() => {
		initialise();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.body}>
			<img src={logo} alt="Shakespeare Logo" />
		</div>
	);
};

export default SplashScreen;
